import { FormControl } from '@angular/forms'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core'
import { Subject, fromEvent } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

@Component({
  selector: 'astutus-combobox',
  templateUrl: './astutus-combobox.component.html',
  styleUrls: ['./astutus-combobox.component.scss'],
})
export class AstutusCombobox implements OnInit {
  /**
   * Elemento filho contendo a listagem.
   */
  @ViewChild('overlay')
  public overlay: any

  /**
   * Parent do container.
   */
  @ViewChild('inputContainer')
  public inputContainer: any

  /**
   * Elemento Input.
   */
  @ViewChild('input')
  public inputElRef: ElementRef

  //---

  /**
   * Label a exibir a que o campo se refere.
   */
  @Input()
  public label: string

  /**
   * Indica qual o atributo será exibido ao selecionar um item.
   * Caso os itens sejam todos strings, não deve ser passado.
   */
  @Input('selected-label')
  public selectedLabel: string

  /**
   * Indica qual o atributo será o valor selecionado.
   * Se não for passado nada, o objeto inteiro é atribuido.
   */
  @Input('selected-value')
  public selectedValue: string

  /**
   * Lista de itens a serem exibidos na listagem.
   */
  @Input()
  public items: Array<any>

  /**
   * Indica se o campo é obrigatório.
   */
  @Input()
  public required: boolean

  /**
   * Atributo para controle do FormControl.
   */
  @Input()
  public formControlRef = new FormControl('')

  /**
   * Mensagem a ser exibida para a validação de obrigatoriedade.
   */
  @Input('required-message')
  public requiredMessage: string = 'Campo obrigatório'

  /**
   * Atributo name para validação.
   */
  @Input()
  public name: string

  /**
   * Utilizado para definir qual a ordem de tabulação deve ser seguida.
   *
   * @type {number}
   */
  @Input()
  public tabindex: number

  //---

  /**
   * Url para quando os dados forem paginados.
   */
  @Input()
  public url: string

  /**
   * Indica por qual atributo será ordenado.
   */
  @Input('order-field')
  public orderField: string

  /**
   * Qual a ordenação utilizada na paginação.
   */
  @Input('order-type')
  public orderType: string = 'ASC'

  /**
   * Campo pelo qual será realizado a busca ao digitar.
   */
  @Input('search-field')
  public searchField: string

  //---

  /**
   * Contem o valor selecionado.
   */
  @Input()
  public selected: Object

  /**
   * Parametros adicionais.
   */
  @Input('additional-parameters')
  public additionalParameters: Array<any>

  /**
   * Evento para quando o valor selecionado mudar.
   */
  @Output()
  public selectedChange: EventEmitter<any> = new EventEmitter()

  public filteredItems: Array<any>

  public inputValue: string

  public valueChanged: Subject<string> = new Subject<string>()

  private hasRequest: boolean

  private paginaAtual: number

  private totalPaginas: number

  public selectedItem: any

  private firstRequest = true

  private static idGenerator = 0

  private static lastClick

  private id

  /**
   * Indica se o componente deve ficar desabilitado.
   */
  public isDisabled: boolean = false

  /**
   * Guarda os requests ativos.
   */
  private requests: any = []

  //---

  constructor(
    private elementRef: ElementRef,
    private http: HttpClient,
    private cdref: ChangeDetectorRef
  ) {
    this.tabindex = 0
    this.id = ++AstutusCombobox.idGenerator
  }

  /**
   * Ao mudar o estatus disabled, altera o Input.
   */
  @Input('disabled') set setDisabled(value: boolean) {
    this.isDisabled = value

    if (value) {
      this.formControlRef.disable()
    } else {
      this.formControlRef.enable()
    }
  }

  ngOnChanges(changes: any) {
    if (changes.selected) {
      let value = changes.selected.currentValue
      let selected = this.selectedValue ? undefined : value

      if ((value || value == '0') && this.items) {
        this.items.forEach(item => {
          let comparacao = this.selectedValue
            ? eval('item.' + this.selectedValue)
            : item

          if (this.equals(value, comparacao)) {
            selected = item
          }
        })
      }

      this.adjustInputValue(selected)
    }

    if (changes.items) {
      this.filteredItems = changes.items.currentValue
    }

    if (this.hasRequest && changes.additionalParameters) {
      this.items = []
      this.paginaAtual = 0

      this.doRequest()
    }
  }

  public ngOnInit() {
    document.body.addEventListener('click', e => {
      this.closeModal()
    })

    this.hasRequest = !!(this.url && this.orderField && this.searchField)

    if (!this.hasRequest) {
      this.filteredItems = this.items
    }

    fromEvent(this.inputElRef.nativeElement, 'keyup')
      .pipe(debounceTime(600))
      .subscribe(keyboardEvent => {
        let event: any = keyboardEvent

        if (event.keyCode == 9 || event.keyCode == 16 || event.keyCode == 91)
          return

        this.onInputEvent(event.target.value)
      })
  }

  public onInputKeyDown(event) {
    if (event.keyCode == 9) {
      this.closeModal(true)
    }
  }

  public onInputFocus() {
    this.openModal()
  }

  public onInputEvent(value) {
    this.selected = undefined
    this.selectedItem = undefined

    if (this.hasRequest) {
      this.items = []
      this.paginaAtual = 0

      this.doRequest(value)
    } else {
      this.filter(value)
    }

    this.selectedChange.emit(this.selected)
  }

  private filter(filter: string) {
    var filteredItems = []

    if (!filter && filter != '0') {
      filteredItems = this.items
    } else {
      this.items.forEach(item => {
        let itemValue = this.selectedLabel
          ? eval('item.' + this.selectedLabel)
          : item

        if (~itemValue.toUpperCase().indexOf(filter.toUpperCase())) {
          filteredItems.push(item)
        }
      })
    }

    this.filteredItems = filteredItems
  }

  private addScrollRequestEvent() {
    const maxScrollHeight = 290
    const itemHeight = 36

    this.overlay.scroller.nativeElement.addEventListener('scroll', e => {
      var top = e.path[0].scrollTop

      if (top + maxScrollHeight >= itemHeight * this.items.length) {
        if (this.paginaAtual < this.totalPaginas) {
          this.doRequest()
        }
      }
    })
  }

  public onSelectChange(item) {
    this.adjustInputValue(item)

    this.selectedChange.emit(this.selected)
    this.filteredItems = this.items
  }

  private equals(x, y) {
    if (x === y) return true
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) return false
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) return false
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x) {
      if (!x.hasOwnProperty(p)) continue
      // other properties were tested using x.constructor === y.constructor

      if (!y.hasOwnProperty(p)) return false
      // allows to compare x[ p ] and y[ p ] when set to undefined

      if (x[p] === y[p]) continue
      // if they have the same strict value or identity then they are equal

      if (typeof x[p] !== 'object') return false
      // Numbers, Strings, Functions, Booleans must be strictly equal

      if (!this.equals(x[p], y[p])) return false
      // Objects and Arrays must be tested recursively
    }

    for (p in y) {
      if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false
      // allows x[ p ] to be set to undefined
    }

    return true
  }

  private adjustInputValue(item) {
    let inputValue = undefined

    if (item == '0' || item) {
      inputValue = this.selectedLabel
        ? eval('item.' + this.selectedLabel)
        : item
      this.selected = this.selectedValue
        ? eval('item.' + this.selectedValue)
        : item
    }

    this.inputValue = inputValue
    this.selectedItem = item
  }

  public getPositionTarget() {
    return this.inputContainer._elementRef.nativeElement
  }

  private openModal() {
    if (this.isDisabled) return

    AstutusCombobox.lastClick = this.id

    setTimeout(() => {
      AstutusCombobox.lastClick = undefined
    }, 200)

    if (this.firstRequest && this.hasRequest) {
      this.firstRequest = false

      this.doRequest()
      this.addScrollRequestEvent()
    }

    this.overlay.moveTo(this.elementRef.nativeElement.parentElement, true)
  }

  private closeModal(force = false) {
    if (force || AstutusCombobox.lastClick != this.id) {
      this.overlay.moveTo(this.elementRef.nativeElement, false)

      if (!this.selectedItem && this.inputValue) {
        this.inputValue = ''
        this.filteredItems = this.items
      }
    }
  }

  private doRequest(searchText = '') {
    var search = [
      'orderType:' + this.orderType,
      'orderField:' + this.orderField,
    ]

    search.push('pag:' + (this.paginaAtual ? ++this.paginaAtual : 1))

    if (searchText != '') {
      search.push(this.searchField + ':' + searchText)
    }

    let oauth = JSON.parse(localStorage.getItem('oauth'))
    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + oauth.access_token,
    })

    this.overlay.showProgress = true

    let request = this.http
      .get(
        this.url +
          '?search=' +
          search.join(',') +
          this.getAdditionaParameters(),
        { headers: headers }
      )
      .subscribe(data => this.onRequestSuccess(data))

    this.requests.push(request)
  }

  /**
   * Retorna os parametros adicionais formatados.
   */
  private getAdditionaParameters() {
    let retorno = ''

    for (let i = 0; i < (this.additionalParameters || []).length; i++) {
      retorno +=
        ',' +
        this.additionalParameters[i].param +
        this.additionalParameters[i].operation +
        this.additionalParameters[i].value
    }

    return retorno
  }

  private onRequestSuccess(response) {
    var items = []

    if (this.items && response.data.paginaAtual > 1) {
      items = this.items
    }

    if (response.data.paginaAtual === 1) {
      this.clearRequests()
    }

    this.paginaAtual = response.data.paginaAtual
    this.totalPaginas = response.data.totalPaginas
    this.items = items.concat(response.data.data)
    this.filteredItems = this.items

    this.overlay.showProgress = false
  }

  /**
   * Cancela os requests ativos.
   */
  private clearRequests() {
    this.requests.forEach(item => item.unsubscribe())
    this.requests = []
  }
}

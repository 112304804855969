import { Component, OnDestroy, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { TranslateStaticLoader } from '../../core/translate.loader'
import { PT_BR } from './i18n/pt-br'
import { EN_US } from './i18n/en-us'
import { ErrorResponse } from '../../shared/error-handler/error-response'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  totalsDashboard: any = {
    totalEmAnalise: 0,
    totalMissionarios: 0,
    totalUsuarios: 0,
    totalVisitantes: 0,
  }

  private apiUrl

  constructor(
    private http: HttpClient,
    private errorResponse: ErrorResponse,
    private translateLoader: TranslateStaticLoader
  ) {
    this.translateLoader.load('ptBR', PT_BR)
    this.translateLoader.load('enUS', EN_US)

    this.apiUrl = `${environment.apiUrl}/admin/indicadores`
  }

  ngOnInit() {
    this.loadDashboard()
  }

  /**
   * Utiliza o observable das igrejas para carregar dos dados do dashboard
   * sempre que uma nova igreja for selecionada no menu.
   */
  private loadDashboard() {
    this.http.get(this.apiUrl).subscribe(
      data => (this.totalsDashboard = (<any>data).data),
      error => this.errorResponse.showMessage(error)
    )
  }
}

export abstract class AbstractFormatter {
  /**
   * O idioma atual da aplicação
   */
  protected lang

  constructor() {
    this.lang = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : 'ptBR'
  }

  /**
   * Deixa apenas números e letras.
   */
  protected clearValue(value) {
    let clearedValue = value.match(/[0-9]/g)

    if (clearedValue == null) {
      clearedValue = []
    }

    return clearedValue.join('')
  }

  /**
   * Formata o valor passado.
   */
  protected formatValue(value = '', mask = '') {
    value = value.toString()

    let output = []
    let count = 0

    for (let i = 0; i < mask.length; i++) {
      if (value[i + count] != undefined) {
        if (mask[i] == '9') {
          if (new RegExp(/[0-9]/).test(value[i + count])) {
            output.push(value[i + count])
          }
        } else if (mask[i] == 'A') {
          if (new RegExp(/[a-zA-Z]/).test(value[i + count])) {
            output.push(value[i + count])
          }
        } else if (mask[i] == undefined) {
          break
        } else {
          output.push(mask[i])
          count--
        }
      }
    }

    return output.join('')
  }
}

import { MatSnackBar } from '@angular/material/snack-bar'
import { Injectable } from '@angular/core'
import * as HttpStatus from 'http-status-codes'
import { TranslateStaticLoader } from '../../core/translate.loader'
import { TranslateService } from '@ngx-translate/core'
import { PT_BR } from './i18n/pt-br'
import { EN_US } from './i18n/en-us'
import { HttpErrorResponse } from '@angular/common/http'

@Injectable()
export class ErrorResponse {
  private messages: { [key: string]: string } = {}

  constructor(
    private snackBar: MatSnackBar,
    private translateLoader: TranslateStaticLoader,
    private translate: TranslateService
  ) {
    this.translateLoader.load('ptBR', PT_BR)
    this.translateLoader.load('enUS', EN_US)

    this.messages[HttpStatus.INTERNAL_SERVER_ERROR] = this.translate.instant(
      'internal_server_error'
    )
    this.messages[HttpStatus.UNAUTHORIZED] = this.translate.instant(
      'unauthorized'
    )
    this.messages[0] = this.translate.instant('internal_server_error')
  }

  showMessage(error: HttpErrorResponse) {
    this.showToastMessage(
      this.messages[error.status] ||
        (error.status == 400 &&
          this.translate.instant(this.getErrorCode(error))) ||
        error.message
    )
  }

  private getErrorCode(error: HttpErrorResponse): string {
    const err: any = error.error?.errors

    if (!err || !err.length) {
      return undefined
    }

    return err[0].message
  }

  private showToastMessage(msg: string) {
    this.snackBar.open(msg, 'OK', {
      duration: 5000,
    })
  }

  addMessage(key: string | number, message: string) {
    this.messages[key] = message
  }
}

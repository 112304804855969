import { Pipe, PipeTransform } from '@angular/core'

/**
 * Formata um número aplicando os milhares e as casas decimais correspondentes
 */
@Pipe({ name: 'numberFormatter' })
export class NumberFormatterPipe implements PipeTransform {
  constructor() {}

  transform(value: string, ...args: any[]): any {
    const decimals = args && args.length > 0 ? parseInt(args[0]) : 2
    let negativeSymbol = ''
    const floatValue: number = parseFloat(value)

    if (!floatValue || isNaN(floatValue)) {
      return '0' + (decimals > 0 ? ',' + this.fill('0', decimals) : '')
    }

    let valor: any = floatValue.toFixed(decimals)

    if (valor.substring(0, 1) == '-') {
      valor = valor.substring(1)
      negativeSymbol = '-'
    }

    valor = valor.split('.')

    let output = ''
    let count = 0

    for (let i = valor[0].length - 1; i >= 0; i--) {
      count++

      output += valor[0][i]

      if (count == 3) {
        count = 0

        if (i - 1 >= 0) {
          output += '.'
        }
      }
    }

    let retorno = output
      .split('')
      .reverse()
      .join('')

    if (decimals) {
      retorno += ',' + valor[1]
    }

    return negativeSymbol + retorno
  }

  private fill(text: string, count: number): string {
    let result: string = ''
    do {
      result += text
      count--
    } while (count > 0)

    return result
  }
}

import { DataSource } from '@angular/cdk/collections'

import { Observable, merge } from 'rxjs'
import { map } from 'rxjs/operators'
import { TableDataCollection } from './table-data-collection'

/**
 * @whatItDoes Fornecesse um DatsSource para conectar uma coleção de dados a uma MatTable.
 *
 * @description Esta classe será utilizada para manipular os dados que
 * serão apresentados na tabela.
 *
 */
export class TableDataSource extends DataSource<any> {
  constructor(private collection: TableDataCollection) {
    super()
  }

  /**
   * Connects a collection viewer (such as a data-table) to this data source. Note that
   * the stream provided will be accessed during change detection and should not directly change
   * values that are bound in template views.
   * @returns Observable that emits a new value when the data changes.
   */
  connect(): Observable<any> {
    const displayDataChanges = [this.collection.dataChange]

    return merge(...displayDataChanges).pipe(
      map(() => {
        return this.collection.data
      })
    )
  }

  /**
   * Disconnects a collection viewer (such as a data-table) from this data source. Can be used
   * to perform any clean-up or tear-down operations when a view is being destroyed.
   *
   */
  disconnect() {}
}

import { Component } from '@angular/core'
import { Subscription } from 'rxjs'
import { MenuService } from '../menu/menu.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public open: boolean = true

  private subscription: Subscription

  constructor(private menuService: MenuService) {
    this.subscription = this.menuService.toggleMenu$.subscribe(open => {
      this.open = open
    })

    this.open = this.menuService.menuOpen
  }

  toggleMenu() {
    this.menuService.toggleMenu()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}

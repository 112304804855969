import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { TranslateModule } from '@ngx-translate/core'
import { UIRouterModule } from '@uirouter/angular'

import { MatSliderModule } from '@angular/material/slider'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatListModule } from '@angular/material/list'
import { MatIconModule } from '@angular/material/icon'
import { MatCardModule } from '@angular/material/card'
import { MatInputModule } from '@angular/material/input'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatChipsModule } from '@angular/material/chips'
import { MatTableModule } from '@angular/material/table'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatSortModule } from '@angular/material/sort'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core'

import { AstutusComboboxModule } from './astutus-combobox/astutus-combobox.module'
import { ErrorResponse } from './error-handler/error-response'
import { AstutusFabModule } from './astutus-fab/fab.module'
import { NumberFormatterPipe } from './pipes/numberFormatter'
import { DateFormatterPipe } from './pipes/dateFormatter'
import { FiltroPeriodoModule } from './filtro-periodo/filtro-periodo.module'
import { DirectivesModule } from './directives/directives.module'
import { BreadcrumbModule } from '../layout/breadcrumb/breadcrumb.module'
import { FiltroPeriodoSemAnoModule } from './filtro-periodo-sem-ano/filtro-periodo-sem-ano.module'
import { LoadingModalModule } from './loading-modal/loading.modal.module'
import { ConfirmationDialogModule } from './confirmation-dialog/confirmation-dialog.module'

// https://angular.io/guide/styleguide#shared-feature-module
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatTableModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatTooltipModule,
    TranslateModule,
    UIRouterModule,
    AstutusComboboxModule,
    AstutusFabModule,
    FiltroPeriodoModule,
    FiltroPeriodoSemAnoModule,
    DirectivesModule,
    BreadcrumbModule,
    LoadingModalModule,
    ConfirmationDialogModule,
  ],
  declarations: [DateFormatterPipe, NumberFormatterPipe],
  providers: [ErrorResponse],
  exports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatTableModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatTooltipModule,
    MatNativeDateModule,
    DateFormatterPipe,
    NumberFormatterPipe,
    TranslateModule,
    UIRouterModule,
    AstutusComboboxModule,
    AstutusFabModule,
    FiltroPeriodoModule,
    FiltroPeriodoSemAnoModule,
    DirectivesModule,
    BreadcrumbModule,
    LoadingModalModule,
    ConfirmationDialogModule,
  ],
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
    }
  }
}

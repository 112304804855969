import { AbstractFormatter } from '../formatter/abstract-formatter'
import { ControlValueAccessor } from '@angular/forms'
import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core'
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms'

const CPF = '999.999.999-99'
const CNPJ = '99.999.999/9999-99'

/**
 * Diretiva para formatação o input que aceita CPF ou CNPJ.
 */
@Directive({
  selector: '[cpfCnpj]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CpfCnpjDirective,
      multi: true,
    },
  ],
})
export class CpfCnpjDirective extends AbstractFormatter
  implements ControlValueAccessor {
  onTouched: any
  onChange: any

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    super()
  }

  writeValue(value: any): void {
    let formatedValue = this.getFormattedValue(value)
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'value',
      formatedValue
    )
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  @HostListener('input', ['$event'])
  onInput($event: any) {
    let clearedValue = this.clearValue($event.target.value)
    let formatedValue = this.getFormattedValue(clearedValue)

    $event.target.value = formatedValue
    this.onChange(this.clearValue(formatedValue))
  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    const clearedValue = this.clearValue($event.target.value)
    if (clearedValue.length !== 11 && clearedValue.length !== 14) {
      $event.target.value = ''
      this.onChange('')
    }

    this.onTouched()
  }

  private getFormattedValue(value) {
    if (!value) {
      return ''
    }

    if (value.length <= 11) {
      return this.formatValue(value, CPF)
    } else {
      return this.formatValue(value, CNPJ)
    }
  }
}

export const PT_BR = {
  email: 'E-mail',
  name: 'Nome',
  current_password: 'Digite senha atual',
  new_password: 'Digite sua nova senha',
  repeat_new_password: 'Confirme sua nova senha',
  current_password_required: 'O campo senha atual é obrigatório',
  new_password_required: 'O campo nova senha é obrigatório',
  new_password_equal: 'A nova senha não deve ser igual a senha atual',
  repeat_new_password_required: 'O campo confirme nova senha é obrigatório',
  repeat_new_password_isntequal:
    'O campo nova senha e confimee nova senha devem ser iguais',
  min_length_3: 'Mínimo de 3 caracteres',
  update_success: 'Seus dados foram alterados com sucesso',
}

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { DataFactory } from '../../shared/factory/data-factory'
import { environment } from 'src/environments/environment'
import { AnaliseMissionario } from './missionario.model'

@Injectable()
export class AnaliseMissionariosService implements DataFactory {
  private apiUrl

  constructor(private http: HttpClient) {
    this.apiUrl = `${environment.apiUrl}/missionario`
  }

  findAllInstituicao() {
    return this.http.get(`${environment.apiUrl}/tipoinstituicao`)
  }

  analiseMissionario(data: AnaliseMissionario): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/admin/analise-missionarios`,
      data
    )
  }

  findAll(search?: string) {
    let findAllUrl = `${this.apiUrl}/search/all`

    if (search) {
      findAllUrl = findAllUrl + search
    }

    return this.http.get(findAllUrl)
  }

  update(model: any) {
    return this.http.put(this.apiUrl, model)
  }

  find(id: number) {
    return this.http.get(`${this.apiUrl}/${id}`)
  }

  getProjetos(id: number) {
    return this.http.get(`${this.apiUrl}/${id}/projeto?page=0&size=200`)
  }

  store(data: any): Observable<any> {
    throw new Error('Method not implemented.')
  }

  destroy(id: number): Observable<any> {
    throw new Error('Method not implemented.')
  }
}

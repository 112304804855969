export const PT_BR = {
  analise_missionarios_title: 'Análise de Missionários',
  filter_title: 'Filtros da pesquisa',
  cadastro: 'Cadastro',
  tipo_instituicao: 'Tipo da Instituição que enviou',
  status_missionario: 'Status Missionário',
  em_analise: 'Em Análise',
  aprovado: 'Aprovado',
  rejeitado: 'Não aprovado',
  todos: 'Todos',
  bispo: 'Bispo',
  padre: 'Padre',
  diacono: 'Diácono',
  leigo: 'Leigo',
  religioso: 'Religioso',
  seminarista: 'Seminarista',
  outro: 'Outro',
  missionarios_title: 'Missionários',
  nome: 'Nome',
  email: 'E-mail',
  data_cadastro: 'Dt. Cadastro',
  status: 'Status',
  sexo: 'Sexo',
  idade: 'Idade',
  missionario_title: 'Detalhe Cadastro Missionário',
  informacoes_pessoais: 'Informações pessoais',
  informacoes_missao: 'Informações da missão',
  qual_arquidiocese: 'Qual a sua arquidiocese, diocese ou prelazia?',
  projetos: 'Projetos',
  cep: 'CEP do Projeto',
  rua: 'Rua do Projeto',
  descricao_projeto: 'Descrição do Projeto',
  numero_rua: 'Nr. Rua do Projeto',
  bairro: 'Bairro do Projeto',
  cidade: 'Cidade do Projeto',
  estado: 'Estado do Projeto',
  pais: 'País do Projeto',
  nome_projeto: 'Nome do Projeto Missionário',
  email_contato: 'E-mail para contato',
  whatsapp: 'WhatsApp',
  facebook: 'URL Facebook',
  twitter: 'URL Twitter',
  instagram: 'URL Instagram',
  informacoes_redes_sociais: 'Informações de redes sociais',
  sexo_0: 'Feminino',
  sexo_1: 'Masculino',
  anos: 'anos',
  opcoes: 'Opções',
  aprovar: 'Aprovar cadastro',
  rejeitar: 'Não aprovar cadastro',
  confirma_aprovacao: 'Confirma a aprovação do cadastro?',
  sim: 'Sim',
  nao: 'Não',
  cadastro_aprovado: 'Cadastro aprovado com sucesso',
  cancelar: 'Cancelar',
  confirmar: 'Confirmar',
  motivo: 'Motivo',
  cadastro_rejeitado: 'Cadastro não aprovado',
  error_projetos: 'Falha ao carregar os projetos do missionário',
}

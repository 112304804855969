import { MenuService } from './menu.service'
import { NgModule } from '@angular/core'
import { MenuComponent } from './menu.component'
import { SharedModule } from '../../shared/shared.module'

@NgModule({
  imports: [SharedModule],
  declarations: [MenuComponent],
  exports: [MenuComponent],
  providers: [MenuService],
})
export class MenuModule {}

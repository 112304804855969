import { Component } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DateAdapter, NativeDateAdapter } from '@angular/material/core'
import { FormControl, Validators } from '@angular/forms'
import { StateService } from '@uirouter/angular'
import { TranslateService } from '@ngx-translate/core'
import { OAuthService } from '../../core/oauth/oauth.service'
import { TranslateStaticLoader } from '../../core/translate.loader'
import { PT_BR } from './i18n/pt-br'
import { EN_US } from './i18n/en-us'
import { saveToken, clearToken } from '../../core/oauth/token.service'
import { ErrorResponse } from 'src/app/shared/error-handler/error-response'
import { JwtHelperService } from '@auth0/angular-jwt'
import { Perfil } from 'src/app/core/oauth/user'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  model: any = {}

  loading = false

  rememberMe = false

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ])

  passwordFormControl = new FormControl('', [Validators.required])

  constructor(
    private oauth: OAuthService,
    private stateService: StateService,
    private translateLoader: TranslateStaticLoader,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<NativeDateAdapter>,
    private errorResponse: ErrorResponse,
    private snackBar: MatSnackBar,
    private jwtService: JwtHelperService
  ) {
    this.translateLoader.load('ptBR', PT_BR)
    this.translateLoader.load('enUS', EN_US)
    this.model.email = localStorage.getItem('email')

    clearToken()
  }

  login(): void {
    this.loading = true

    if (this.emailFormControl.valid && this.passwordFormControl.valid) {
      this.oauth.login(this.model.email, this.model.password).subscribe(
        data => this.onLoginSuccess(data),
        error => this.errorResponse.showMessage(error)
      )
    }

    this.loading = false
  }

  public onLoginSuccess(response): void {
    if (!this.isAdmin(response?.data)) {
      return this.showMessageInvalidProfile()
    }

    if (this.rememberMe) {
      localStorage.setItem('email', this.model.email)
    } else {
      localStorage.removeItem('email')
    }

    saveToken(response.data)
    this.stateService.go('dashboard')
  }

  private isAdmin(token: string): boolean {
    if (!token) {
      return false
    }

    const user: any = this.jwtService.decodeToken(token)

    return user?.groups[0] == Perfil.ADMINISTRADOR
  }

  private showMessageInvalidProfile() {
    this.snackBar.open(this.translate.instant('invalid_profile'), 'OK', {
      duration: 5000,
    })
  }

  changeLang(lang: string, event: Event) {
    event.preventDefault()
    localStorage.setItem('lang', lang)
    this.translate.use(lang)
    this.dateAdapter.setLocale(lang.substring(0, 2) + '-' + lang.substring(2))
  }
}

import { Injectable } from '@angular/core'
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { LoadingModalComponent } from '../../shared/loading-modal/loading.modal.component'
import { finalize } from 'rxjs/operators'

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private dialog: MatDialog) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.endsWith('pdf') ||
      req.url.endsWith('xlsx') ||
      req.url.endsWith('xls')
    ) {
      const type: string = req.url
        .substring(req.url.lastIndexOf('/') + 1)
        .toUpperCase()
      let dialogRef = this.dialog.open(LoadingModalComponent, {
        data: {
          message: `Exportando relatório em ${type}, aguarde...`,
        },
        disableClose: true,
      })

      return next.handle(req).pipe(finalize(() => dialogRef.close()))
    }

    return next.handle(req)
  }
}

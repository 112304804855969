import { NativeDateAdapter } from '@angular/material/core'
import { Injectable } from '@angular/core'

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if (!value || value.toString().length != 10) {
      return null
    }
    let d = value.toString().split('/')
    if (d.length != 3) {
      return null
    }
    if (this.locale === 'en-US') {
      return this.createDate(parseInt(d[2]), parseInt(d[0]) - 1, parseInt(d[1]))
    } else {
      return this.createDate(parseInt(d[2]), parseInt(d[1]) - 1, parseInt(d[0]))
    }
  }
}

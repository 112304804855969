const ACCESS_TOKEN_KEY: string = 'access_token'

export function tokenLoader() {
  return localStorage.getItem(ACCESS_TOKEN_KEY)
}

export function saveToken(token: string) {
  localStorage.setItem(ACCESS_TOKEN_KEY, token)
}

export function clearToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
}

<div class="periodo-container">
  <astutus-combobox
    class="filter-type"
    label="{{ label ? label : ('periodo' | translate) }}"
    selected-label="descricao"
    [(selected)]="periodo"
    [items]="periodos"
    (selectedChange)="changePeriodo()"
  >
  </astutus-combobox>

  <div *ngIf="customDate && !mostrarHoras" class="custom-date">
    <mat-form-field class="data-inicial-filter">
      <input
        matInput
        [matDatepicker]="pickerInicial"
        [value]="periodo.dataInicial"
        placeholder="{{ 'data_inicial' | translate }}"
        (dateInput)="dataInicialChanged($event)"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="pickerInicial"
      ></mat-datepicker-toggle>
      <mat-datepicker #pickerInicial></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="data-final-filter">
      <input
        matInput
        [matDatepicker]="pickerFinal"
        [value]="periodo.dataFinal"
        placeholder="{{ 'data_final' | translate }}"
        (dateInput)="dataFinalChanged($event)"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="pickerFinal"
      ></mat-datepicker-toggle>
      <mat-datepicker #pickerFinal></mat-datepicker>
    </mat-form-field>
  </div>

  <div
    *ngIf="customDate && mostrarHoras"
    class="grid-container custom-date space-left"
  >
    <div class="grid-flex">
      <mat-form-field class="data-inicial-filter">
        <input
          matInput
          [matDatepicker]="pickerInicial"
          [value]="periodo.dataInicial"
          placeholder="{{ 'data_inicial' | translate }}"
          (dateInput)="dataInicialChanged($event)"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerInicial"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerInicial></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="data-final-filter">
        <input
          matInput
          textFormat="99:99"
          [(ngModel)]="horaInicial"
          placeholder="{{ 'hora_inicial' | translate }}"
          (keyup)="horaInicialChanged($event)"
        />
      </mat-form-field>
    </div>

    <div class="grid-flex">
      <mat-form-field class="data-final-filter">
        <input
          matInput
          [matDatepicker]="pickerFinal"
          [value]="periodo.dataFinal"
          placeholder="{{ 'data_final' | translate }}"
          (dateInput)="dataFinalChanged($event)"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerFinal"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerFinal></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="data-final-filter">
        <input
          matInput
          textFormat="99:99"
          [(ngModel)]="horaFinal"
          placeholder="{{ 'hora_final' | translate }}"
          (keyup)="horaFinalChanged($event)"
        />
      </mat-form-field>
    </div>
  </div>
</div>

import { Component, Output, OnInit, EventEmitter } from '@angular/core'
import { TranslateStaticLoader } from '../../core/translate.loader'
import { TranslateService } from '@ngx-translate/core'
import { PT_BR } from './i18n/pt-br'
import { EN_US } from './i18n/en-us'
import { PeriodoSemAno } from './periodo-sem-ano'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DateAdapter } from '@angular/material/core'

/**
 * Componente de filtro com períodos pré-definidos e uma opção de datas personalizadas
 */
@Component({
  selector: 'filtro-periodo-sem-ano',
  templateUrl: './filtro-periodo-sem-ano.component.html',
  styleUrls: ['./filtro-periodo-sem-ano.component.scss'],
})
export class FiltroPeriodoSemAno implements OnInit {
  /**
   * Período para ser inicializado.
   *
   * 0 - Hoje
   * 1 - Esta semana
   * 2 - Próxima Semana
   * 3 - Este mês
   * 4 - Próximo mês
   * 5 - Personalizado
   */
  private initType: number = 0

  /**
   * Lista com os períodos pré-definidos
   */
  public periodos: PeriodoSemAno[] = []

  /**
   * Período atual selecionado
   */
  public periodo: PeriodoSemAno = new PeriodoSemAno()

  /**
   * Indica se a data é personalizada ou pré-definida
   */
  public customDate: boolean = false

  /**
   * Dispara o evento de change ao alterar o período ou a data personalizada
   */
  @Output()
  public periodoChanged: EventEmitter<PeriodoSemAno> = new EventEmitter<
    PeriodoSemAno
  >()

  constructor(
    private translateLoader: TranslateStaticLoader,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    private snackBar: MatSnackBar
  ) {
    this.translateLoader.load('ptBR', PT_BR)
    this.translateLoader.load('enUS', EN_US)
  }

  /**
   * Posiciona o período selecionado em "Hoje" ao iniciar o componente
   */
  public setToday() {
    this.initType = 0
    if (this.periodos.length > 0) {
      this.periodo = this.periodos[this.initType]
      this.filtersChanged()
    }
  }

  /**
   * Posiciona o período selecionado em "Este mês" ao iniciar o componente
   */
  public setThisMonth() {
    this.initType = 3
    if (this.periodos.length > 0) {
      this.periodo = this.periodos[this.initType]
      this.filtersChanged()
    }
  }

  /**
   * Inicializa os períodos pré-definidos e o período inicial selecionado,
   * disparando o evento change de notificação
   */
  public ngOnInit(): void {
    this.periodos.push(this.getToday())
    this.periodos.push(this.getThisWeek())
    this.periodos.push(this.getNextWeek())
    this.periodos.push(this.getThisMonth())
    this.periodos.push(this.getNextMonth())
    this.periodos.push(this.getCustom())

    this.periodo = this.periodos[this.initType]

    this.filtersChanged()
  }

  /**
   * Monta o período Hoje
   */
  private getToday(): PeriodoSemAno {
    return {
      descricao: this.translate.instant('hoje'),
      periodoInicial: this.formatDate(new Date()),
      periodoFinal: this.formatDate(new Date()),
    }
  }

  /**
   * Monta o período Esta semana
   */
  private getThisWeek(): PeriodoSemAno {
    return {
      descricao: this.translate.instant('esta_semana'),
      periodoInicial: this.formatDate(this.getWeekStart()),
      periodoFinal: this.formatDate(this.getWeekEnd()),
    }
  }

  /**
   * Monta o período Próxima Semana
   */
  private getNextWeek(): PeriodoSemAno {
    return {
      descricao: this.translate.instant('proxima_semana'),
      periodoInicial: this.formatDate(this.getNextWeekStart()),
      periodoFinal: this.formatDate(this.getNextWeekEnd()),
    }
  }

  /**
   * Monta o período Este mês
   */
  private getThisMonth(): PeriodoSemAno {
    return {
      descricao: this.translate.instant('este_mes'),
      periodoInicial: this.formatDate(this.getMonthStart()),
      periodoFinal: this.formatDate(this.getMonthEnd()),
    }
  }

  /**
   * Monta o período Próximo mês
   */
  private getNextMonth(): PeriodoSemAno {
    return {
      descricao: this.translate.instant('proximo_mes'),
      periodoInicial: this.formatDate(this.getNextMonthStart()),
      periodoFinal: this.formatDate(this.getNextMonthEnd()),
    }
  }

  /**
   * Monta o período Personalizado
   */
  private getCustom(): PeriodoSemAno {
    return {
      descricao: this.translate.instant('personalizado'),
      periodoInicial: this.formatDate(this.getMonthStart()),
      periodoFinal: this.formatDate(this.getMonthEnd()),
    }
  }

  /**
   * Monta a data no domingo da semana atual
   */
  private getWeekStart(): Date {
    const date: Date = new Date()
    date.setDate(date.getDate() - date.getDay())

    return date
  }

  /**
   * Monta a data no sábado da semana atual
   */
  private getWeekEnd(): Date {
    const date: Date = new Date()
    date.setDate(date.getDate() + 6 - date.getDay())

    return date
  }

  /**
   * Monta a data de domingo da próxima semana
   */
  private getNextWeekStart(): Date {
    const date: Date = new Date()
    date.setDate(date.getDate() + (7 - date.getDay()))

    return date
  }

  /**
   * Monta a data da sábado da próxima semana
   */
  private getNextWeekEnd(): Date {
    const date: Date = new Date()
    date.setDate(date.getDate() + 7 + (6 - date.getDay()))

    return date
  }

  /**
   * Monta a data de início do próximo mês
   */
  private getNextMonthStart(): Date {
    const date: Date = new Date()
    date.setMonth(date.getMonth() + 1)
    date.setDate(1)

    return date
  }

  /**
   * Monta a data do último dia do próximo mês
   */
  private getNextMonthEnd(): Date {
    const date: Date = new Date()
    date.setMonth(date.getMonth() + 2)
    date.setDate(0)

    return date
  }

  /**
   * Monta a data no primeiro dia do mês
   */
  private getMonthStart(): Date {
    const date: Date = new Date()
    date.setDate(1)

    return date
  }

  /**
   * Monta a data no último dia do mês
   */
  private getMonthEnd(): Date {
    const date: Date = new Date()
    date.setMonth(date.getMonth() + 1)
    date.setDate(0)

    return date
  }

  /**
   * Monta a data do primeiro dia do mês passado
   */
  private getLastMonthStart(): Date {
    const date: Date = this.getMonthStart()
    date.setMonth(date.getMonth() - 1)

    return date
  }

  /**
   * Monta a data do último dia do mês passado
   */
  private getLastMonthEnd(): Date {
    const date: Date = this.getLastMonthStart()
    date.setMonth(date.getMonth() + 1)
    date.setDate(0)

    return date
  }

  /**
   * Notifica quando alterar uma data do período
   */
  public filtersChanged() {
    this.periodoChanged.emit(this.parsePeriodo(this.periodo))
  }

  /**
   * Notifica quando altera o período todo
   */
  public changePeriodo() {
    this.customDate = this.periodos[this.periodos.length - 1] == this.periodo
    this.filtersChanged()
  }

  /**
   * Notifica quando há mudança na data inicial através do datePicker
   * @param event
   */
  public dataInicialChanged() {
    if (
      this.periodo.periodoInicial.length === 4 &&
      this.periodValid(this.periodo.periodoInicial, 'inicial')
    ) {
      this.filtersChanged()
    }
  }

  /**
   * Notifica quando há mudança na data final através do datePicker
   */
  public dataFinalChanged() {
    if (
      this.periodo.periodoFinal.length === 4 &&
      this.periodValid(this.periodo.periodoFinal, 'final')
    ) {
      this.filtersChanged()
    }
  }

  /**
   * Formata uma data em dd/MM
   */
  private formatDate(date: Date): string {
    let dateFormat: any = {
      month: '2-digit',
      day: '2-digit',
    }

    return this.dateAdapter
      .format(date, dateFormat)
      .split('/')
      .join('')
  }

  /**
   * Valida se o período informado é um dia e mês válido
   */
  private periodValid(periodo, tipo) {
    if (periodo.length != 4) {
      this.showMessage('Dia/mês ' + tipo + ' inválido')
      return false
    }

    let month = parseInt(periodo.substring(2))
    if (month < 1 || month > 12) {
      this.showMessage('Dia/mês ' + tipo + ' inválido')
      return false
    }

    let date: Date = new Date()
    date.setMonth(month - 1)
    date.setDate(parseInt(periodo.substring(0, 2)))

    if (isNaN(date.getTime()) || date.getMonth() != month - 1) {
      this.showMessage('Dia/mês ' + tipo + ' inválido')
      return false
    }

    return true
  }

  private showMessage(msg) {
    this.snackBar.open(msg, null, {
      duration: 5000,
    })
  }

  /**
   * Converte os períodos inicial e final no formato correto
   * @param periodo
   */
  private parsePeriodo(periodo: PeriodoSemAno): PeriodoSemAno {
    const novoPeriodo: PeriodoSemAno = new PeriodoSemAno()
    novoPeriodo.descricao = periodo.descricao
    novoPeriodo.periodoInicial = this.getDiaMes(periodo.periodoInicial)
    novoPeriodo.periodoFinal = this.getDiaMes(periodo.periodoFinal)

    return novoPeriodo
  }

  /**
   * Inverte o mês e o dia no período. O input será ddMM e o output será MMdd
   *
   * @param periodo
   */
  private getDiaMes(periodo) {
    return periodo.substring(2) + periodo.substring(0, 2)
  }
}

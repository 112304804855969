export enum StatusCadastro {
  EM_ANALISE = 'EM_ANALISE',
  APROVADO = 'APROVADO',
  REJEITADO = 'REJEITADO',
}

export enum TipoMissionario {
  BISPO = 'BISPO',
  PADRE = 'PADRE',
  DIACONO = 'DIACONO',
  LEIGO = 'LEIGO',
  RELIGIOSO = 'RELIGIOSO',
  SEMINARISTA = 'SEMINARISTA',
  OUTRO = 'OUTRO',
}

export enum Sexo {
  FEMININO = 0,
  MASCULINO = 1,
}

export enum TipoAnalise {
  APROVADO = 0,
  REJEITADO = 1,
}

export interface AnaliseMissionario {
  idRegMissionario: number
  tpStatus: number
  dsMotivo?: string
}

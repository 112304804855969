import { FiltroPeriodoSemAno } from './filtro-periodo-sem-ano.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatInputModule } from '@angular/material/input'
import { TranslateModule } from '@ngx-translate/core'
import { AstutusComboboxModule } from '../astutus-combobox/astutus-combobox.module'
import { FormsModule } from '@angular/forms'
import { DirectivesModule } from '../directives/directives.module'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    TranslateModule,
    AstutusComboboxModule,
    DirectivesModule,
  ],
  declarations: [FiltroPeriodoSemAno],
  exports: [FiltroPeriodoSemAno],
})
export class FiltroPeriodoSemAnoModule {}

<div class="nav-bar mat-toolbar">
  <div class="page-logo" [class.closed]="!open">
    <a class="logo" [class.hidden]="!open">
      <img srcset="/assets/logo-ad-gentes.png" alt="Logo" />
    </a>
    <a class="menu-toggler" (click)="toggleMenu()"
      ><mat-icon class="fa fa-bars"></mat-icon
    ></a>
  </div>
  <div class="middle"></div>
  <div class="right">
    <user class="item"></user>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { DateParser } from '../../core/date.parser'

/**
 * Formata uma data com base no locale atual, ignorando o horário.
 * Pode ser parametrizado uma configuração baseada no MAT_DATE_FORMATS que será usada no DateAdapter.
 * Esta configuração pode conter:
 * year: numeric | 2-digit | false
 * month: long | short | narrow| numeric | 2-digit | false
 * day: numeric | 2-digit | false
 *
 * Quando a opção for false a parte correspondente será removida da formatação.
 * Ex: {year: false} produzirá dd/MM
 */
@Pipe({ name: 'dateFormatter' })
export class DateFormatterPipe implements PipeTransform {
  constructor(private dateAdapter: DateAdapter<Date>) {}

  transform(value: string, ...args: any[]): any {
    const date: Date = DateParser.parse(value)

    if (!date || isNaN(date.getTime())) {
      return value
    }

    const dateFormat: any = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }

    if (args) {
      args.forEach(item => {
        const filters: string = item.split(':')
        switch (filters[0]) {
          case 'year':
            if (filters[1] === 'false') {
              delete dateFormat.year
            } else {
              dateFormat.year = filters[1] || 'numeric'
            }
            break
          case 'month':
            if (filters[1] === 'false') {
              delete dateFormat.month
            } else {
              dateFormat.month = filters[1] || '2-digit'
            }
            break
          case 'day':
            if (filters[1] === 'false') {
              delete dateFormat.day
            } else {
              dateFormat.day = filters[1] || '2-digit'
            }
            break
        }
      })
    }

    return this.dateAdapter.format(date, dateFormat)
  }
}

import { UIRouterModule, UIRouter } from '@uirouter/angular'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

export function uiRouterConfig(router: UIRouter, injector: any) {
  router.urlService.rules.when('', '/home')
  router.urlService.rules.otherwise({ state: 'home' })
}

@NgModule({
  imports: [CommonModule, UIRouterModule.forRoot({ config: uiRouterConfig })],
  exports: [UIRouterModule],
})
export class AppRoutingModule {}

import { FabOption } from './fab.option'
import {
  Renderer2,
  ViewChild,
  ElementRef,
  Component,
  Input,
} from '@angular/core'

@Component({
  selector: 'astutus-fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss'],
})
export class AstutusFab {
  @Input('disabled')
  disabled: boolean = false

  @Input('options')
  options: FabOption

  @ViewChild('content') content: ElementRef

  @ViewChild('background') background: ElementRef

  @ViewChild('anchor', { read: ElementRef }) anchor: ElementRef

  isOpen: boolean = false

  open() {
    if (this.disabled) {
      return
    }

    this.isOpen = true
    const padding: number = 10
    const margin: number = this.anchor.nativeElement.offsetHeight

    let elements = this.content.nativeElement.children
    let totalHeight: number = 0

    for (let i = 0; i < elements.length; i++) {
      let element: HTMLElement = elements[i]
      const top: number = i * element.offsetHeight + padding * (i + 1) + margin

      element.style.top = -top + 'px'
      totalHeight = element.offsetHeight + padding * (i + 1) + margin
    }

    this.background.nativeElement.style.height = totalHeight + 'px'
    this.background.nativeElement.style.top = -totalHeight + 'px'
  }

  close() {
    this.isOpen = false
    let elements = this.content.nativeElement.children
    for (let i = 0; i < elements.length; i++) {
      let element: HTMLElement = elements[i]
      element.style.top = '0'
    }

    this.background.nativeElement.style.height = '0'
  }
}

import { UIRouterModule } from '@uirouter/angular'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LayoutComponent } from '../layout/layout.component'
import { LoginComponent } from './login/login.component'

const states = [
  {
    name: 'layout',
    url: '/',
    views: {
      'page-content@': {
        component: LayoutComponent,
      },
    },
    data: {
      authRequired: true,
    },
    redirectTo: 'home',
  },
  {
    name: 'login',
    url: '/login',
    views: {
      'page-content@': {
        component: LoginComponent,
      },
    },
  },
]

@NgModule({
  imports: [CommonModule, UIRouterModule.forChild({ states: states })],
  declarations: [],
  exports: [UIRouterModule],
})
export class PagesRoutingModule {}

import { NgModule } from '@angular/core'
import { CurrencyPipe } from '@angular/common'

import { HomeComponent } from './home.component'
import { HomeRoutingModule } from './home.routing.module'
import { SharedModule } from '../../shared/shared.module'
import { NumberFormatterPipe } from '../../shared/pipes/numberFormatter'

@NgModule({
  imports: [SharedModule, HomeRoutingModule],
  providers: [CurrencyPipe, NumberFormatterPipe],
  declarations: [HomeComponent],
})
export class HomeModule {}

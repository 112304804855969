import { Injectable } from '@angular/core'
import { TransitionService, Transition } from '@uirouter/angular'

import { Subject } from 'rxjs'

@Injectable()
export class RouteService {
  private routeChanged = new Subject<Transition>()

  routeChanged$ = this.routeChanged.asObservable()

  constructor(private transitionService: TransitionService) {
    this.transitionService.onSuccess({ to: '*' }, event => {
      this.routeChanged.next(event)
    })
  }
}

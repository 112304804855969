import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatDialogModule } from '@angular/material/dialog'
import { LoadingModalComponent } from './loading.modal.component'

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, MatDialogModule],
  declarations: [LoadingModalComponent],
  exports: [LoadingModalComponent],
  entryComponents: [LoadingModalComponent],
})
export class LoadingModalModule {}

export class DateParser {
  static parse(value: any): Date {
    if (!value) {
      return null
    }

    let date: Date
    let dateString

    if (
      value.toString().indexOf('T') > -1 ||
      value.toString().indexOf(' ') > -1
    ) {
      dateString = value.split('T')[0].split(' ')[0]
    } else {
      dateString = value
    }

    if (dateString && dateString.toString().indexOf('-') > -1) {
      let d = dateString.split('-')
      date = new Date(parseInt(d[0]), parseInt(d[1]) - 1, parseInt(d[2]), 12)
    } else {
      date = new Date(dateString)
    }

    return date
  }

  static format(date: Date): string {
    if (!date || isNaN(date.getTime())) {
      return null
    }

    return (
      date.getFullYear() +
      '-' +
      DateParser.fill(date.getMonth() + 1) +
      '-' +
      DateParser.fill(date.getDate())
    )
  }

  static fill(value: number): string {
    if (value < 10) {
      return '0' + value
    }
    return value.toString()
  }
}

import { NgModule } from '@angular/core'
import { HeaderModule } from './header/header.module'
import { UsuarioModule } from './usuario/usuario.module'
import { LayoutComponent } from './layout.component'
import { FooterComponent } from './footer/footer.component'
import { MenuModule } from './menu/menu.module'
import { BreadcrumbModule } from './breadcrumb/breadcrumb.module'
import { SharedModule } from '../shared/shared.module'

@NgModule({
  imports: [
    SharedModule,
    MenuModule,
    BreadcrumbModule,

    HeaderModule,

    UsuarioModule,
  ],
  providers: [],
  declarations: [LayoutComponent, FooterComponent],
  exports: [LayoutComponent, FooterComponent],
})
export class LayoutModule {}

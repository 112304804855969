import { NgModule } from '@angular/core'
import { SharedModule } from '../shared/shared.module'
import { HomeModule } from './home/home.module'
import { LoginModule } from './login/login.module'
import { PagesRoutingModule } from './pages-routing.module'
import { EsqueceuSenhaComponent } from './esqueceu-senha/esqueceu-senha.component'
import { ResetarSenhaComponent } from './resetar-senha/resetar-senha.component'
import { ContaModule } from './conta/conta.module'
import { AnaliseMissionariosModule } from './analise-missionarios/analise-missionarios.module'
import { PostPublicoModule } from './post-publico/post-publico.module'

@NgModule({
  imports: [
    SharedModule,
    PagesRoutingModule,
    HomeModule,
    LoginModule,
    ContaModule,
    AnaliseMissionariosModule,
    PostPublicoModule,
  ],
  declarations: [EsqueceuSenhaComponent, ResetarSenhaComponent],
  providers: [],
  exports: [
    PagesRoutingModule,
    HomeModule,
    LoginModule,
    ContaModule,
    AnaliseMissionariosModule,
    PostPublicoModule,
  ],
})
export class PagesModule {}

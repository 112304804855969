export const EN_US = {
  email: 'E-mail',
  name: 'Name',
  current_password: 'Current password',
  new_password: 'New password',
  repeat_new_password: 'Repeat new password',
  current_password_required: 'Current password is required',
  new_password_required: 'New password is required',
  new_password_equal: 'New password must be different from current password',
  repeat_new_password_required: 'Repeat password is required',
  repeat_new_password_isntequal:
    'Repeat password must be equals to new password',
  min_length_3: 'Minimum of 3 characters',
  update_success: 'Your profile was updated successfully',
}

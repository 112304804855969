export interface User {
  dsEmail: string
  dsNome?: string
  tpPerfil?: Perfil
  dsSenha?: string
}

export enum Perfil {
  ADMINISTRADOR = 'Admin',
  VISITANTE = 'Visitor',
  MISSIONARIO = 'Missionary',
}

export class PeriodoSemAno {
  descricao: string

  /**
   * Composto por mês e dia sem caracteres e com padding.
   * Ex: 0203 - para 03 de Fevereiro
   */
  periodoInicial: string
  periodoFinal: string
}

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { OAuthService } from '../../core/oauth/oauth.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TranslateStaticLoader } from '../../core/translate.loader'
import { PT_BR } from './i18n/pt-br'
import { EN_US } from './i18n/en-us'
import { User } from 'src/app/core/oauth/user'
import { ErrorResponse } from 'src/app/shared/error-handler/error-response'
import { TranslateService } from '@ngx-translate/core'
import { saveToken } from 'src/app/core/oauth/token.service'

@Component({
  selector: 'app-conta',
  templateUrl: './conta.component.html',
  styleUrls: ['./conta.component.scss'],
})
export class ContaComponent implements OnInit {
  form: FormGroup
  saving: boolean = false

  emailFormControl = new FormControl({ value: '', disabled: true })
  nameFormControl = new FormControl('', [Validators.required])
  currentPasswordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
  ])
  newPasswordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
  ])
  repeatNewPasswordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
  ])

  constructor(
    private oauth: OAuthService,
    private snackBar: MatSnackBar,
    private translateLoader: TranslateStaticLoader,
    private errorResponse: ErrorResponse,
    private translate: TranslateService
  ) {
    this.translateLoader.load('ptBR', PT_BR)
    this.translateLoader.load('enUS', EN_US)
  }

  ngOnInit() {
    this.form = new FormGroup({
      dsNome: this.nameFormControl,
      dsEmail: this.emailFormControl,
      current_password: this.currentPasswordFormControl,
      new_password: this.newPasswordFormControl,
      repeat_new_password: this.repeatNewPasswordFormControl,
    })

    const user: User = this.oauth.currentUser()
    this.form.setValue({
      dsNome: user.dsNome,
      dsEmail: user.dsEmail,
      current_password: '',
      new_password: '',
      repeat_new_password: '',
    })
  }

  save() {
    this.nameFormControl.markAsTouched()
    this.currentPasswordFormControl.markAsTouched()
    this.newPasswordFormControl.markAsTouched()
    this.repeatNewPasswordFormControl.markAsTouched()

    let isntEqual = true
    let isEqual = false

    if (
      this.nameFormControl.invalid ||
      this.currentPasswordFormControl.invalid
    ) {
      return
    }

    this.newPasswordFormControl.setErrors(null)

    if (
      this.newPasswordFormControl.valid &&
      this.repeatNewPasswordFormControl.valid
    ) {
      isntEqual =
        this.newPasswordFormControl.value !==
        this.repeatNewPasswordFormControl.value

      if (isntEqual) {
        this.repeatNewPasswordFormControl.setErrors({ isntEqual: isntEqual })
      }
    }

    if (
      this.currentPasswordFormControl.valid &&
      this.newPasswordFormControl.valid
    ) {
      isEqual =
        this.currentPasswordFormControl.value ===
        this.newPasswordFormControl.value

      if (isEqual) {
        this.newPasswordFormControl.setErrors({ isEqual: isEqual })
      } else {
        this.newPasswordFormControl.setErrors(null)
      }
    }

    if (this.form.valid) {
      this.saving = true
      this.oauth
        .updatePerfil({
          dsNome: this.nameFormControl.value,
          dsSenhaAtual: this.currentPasswordFormControl.value,
          dsNovaSenha: this.newPasswordFormControl.value,
        })
        .subscribe(
          () => this.onChangePasswordSuccess(),
          error => {
            this.saving = false
            this.errorResponse.showMessage(error)
          }
        )
    }
  }

  onChangePasswordSuccess() {
    this.oauth
      .login(this.emailFormControl.value, this.newPasswordFormControl.value)
      .subscribe(data => this.updateUserLoggedIn(data))

    this.snackBar.open(this.translate.instant('update_success'), 'OK', {
      duration: 5000,
    })

    this.currentPasswordFormControl.reset()
    this.newPasswordFormControl.reset()
    this.repeatNewPasswordFormControl.reset()
  }

  private updateUserLoggedIn(data: any) {
    this.saving = false
    saveToken(data.data)
    this.oauth.currentUserChanged()
  }
}

<mat-progress-bar
  mode="indeterminate"
  [style.visibility]="showProgress ? 'visible' : 'hidden'"
></mat-progress-bar>
<div #scroller id="scroller">
  <astutus-combobox-item
    *ngFor="let item of items"
    [item]="item"
    label="{{ getLabel(item) }}"
    (select)="onSelectItem($event)"
    [ngClass]="{ selected: isSelected(selected, item) }"
  ></astutus-combobox-item>
</div>

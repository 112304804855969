import { UIRouterModule } from '@uirouter/angular'
import { NgModule } from '@angular/core'

import { ContaComponent } from './conta.component'

const states = [
  {
    name: 'conta',
    url: 'conta',
    parent: 'layout',
    views: {
      'layout-content@layout': {
        component: ContaComponent,
      },
    },
    data: {
      breadcrumb: 'Conta',
    },
  },
]

@NgModule({
  imports: [UIRouterModule.forChild({ states: states })],
  exports: [UIRouterModule],
})
export class ContaRoutingModule {}

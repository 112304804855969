<div class="wrapper">
  <section class="parallax-background forgot-password">
    <div id="login-v2-form-wrapper" class="flex-scrollable mat-whiteframe-8dp">
      <div id="login-v2-form">
        <div class="logo">
          <img src="assets/logo-ad-gentes.png" alt="Logo" />
        </div>

        <div
          *ngIf="requestSuccess"
          class="alert alert-success alert-dismissible"
          role="alert"
        >
          {{ 'request_success' | translate }}
        </div>

        <form
          class="recover-password-form"
          (ngSubmit)="recover()"
          #f="ngForm"
          novalidate
        >
          <mat-form-field class="mat-block">
            <mat-icon
              matPrefix
              class="fa fa-user font-grey-salt icon-pesquisa"
            ></mat-icon>
            <input
              matInput
              type="email"
              name="username"
              placeholder="{{ 'email' | translate }}"
              required
              [formControl]="emailFormControl"
              [(ngModel)]="model.email"
            />
            <mat-error *ngIf="emailFormControl.hasError('email')">
              {{ 'invalid_email' | translate }}
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              {{ 'required_email' | translate }}
            </mat-error>
          </mat-form-field>
          <button mat-button class="reset-button red-intense">
            {{ 'send_button' | translate }}
          </button>
        </form>
      </div>
    </div>
  </section>
</div>

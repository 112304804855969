import { AbstractFormatter } from '../formatter/abstract-formatter'
import { ControlValueAccessor } from '@angular/forms'
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChange,
} from '@angular/core'
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms'

/**
 * Diretiva para formatação de texto.
 *
 * Ex: 99999-999 -> format -> 85618-000
 */
@Directive({
  selector: '[textFormat]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextFormatDirective,
      multi: true,
    },
  ],
})
export class TextFormatDirective extends AbstractFormatter
  implements ControlValueAccessor {
  onTouched: any
  onChange: any

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    super()
  }

  /**
   * Carrega o padrão de formatação.
   */
  @Input('textFormat') format: string

  writeValue(value: any): void {
    let formatedValue = this.formatValue(value ? value : '', this.format)
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'value',
      formatedValue
    )
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  @HostListener('input', ['$event'])
  onInput($event: any) {
    let clearedValue = this.clearValue($event.target.value)
    let formatedValue = this.formatValue(clearedValue, this.format)

    $event.target.value = formatedValue
    this.onChange(this.clearValue(formatedValue))
  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    if ($event.target.value.length !== this.format.length) {
      $event.target.value = ''
      this.onChange('')
    }

    this.onTouched()
  }
}

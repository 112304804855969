<section class="dashboard-section">
  <div class="dashboard-content">
    <div class="grid-container">
      <div class="grid-item">
        <div class="blue dashboard-stat">
          <div class="details">
            <div class="number">
              <span>{{ totalsDashboard.totalUsuarios }}</span>
            </div>
            <div class="desc">{{ 'usuarios' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="grid-item">
        <div class="red-thunderbird dashboard-stat">
          <div class="details">
            <div class="number">
              <span>{{ totalsDashboard.totalMissionarios }}</span>
            </div>
            <div class="desc">{{ 'missionarios' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="grid-item">
        <div class="green-jungle dashboard-stat">
          <div class="details">
            <div class="number">
              <span>{{ totalsDashboard.totalVisitantes }}</span>
            </div>
            <div class="desc">{{ 'visitantes' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="grid-item last">
        <div class="yellow dashboard-stat">
          <div class="details">
            <div class="number">
              <span>{{ totalsDashboard.totalEmAnalise }}</span>
            </div>
            <div class="desc">{{ 'em_analise' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<h2 mat-dialog-title>{{ 'rejeitar' | translate }}</h2>
<mat-dialog-content>
  <form>
    <mat-form-field>
      <input
        matInput
        required
        name="dsMotivo"
        maxlength="256"
        placeholder="{{ 'motivo' | translate }}"
        [formControl]="motivoFormControl"
      />
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <button mat-button mat-dialog-close class="cancel-btn">
    {{ 'cancelar' | translate }}
  </button>
  <button mat-button (click)="rejeitar()" class="confirm-btn">
    {{ 'confirmar' | translate }}
  </button>
</mat-dialog-actions>

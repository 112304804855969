import { CpfCnpjDirective } from './cpfCnpj'
import { NgModule } from '@angular/core'
import { ClickOutside } from './click-outside.directive'
import { DateInputDirective } from './dateInput'
import { DebounceDelay } from './debounce'
import { TextFormatDirective } from './textFormat'
import { NumericoDirective } from './numerico'
import { DateMaskDirective } from './dateMask'
import { TimeMaskDirective } from './timeMask'

@NgModule({
  declarations: [
    ClickOutside,
    DateInputDirective,
    DebounceDelay,
    TextFormatDirective,
    NumericoDirective,
    CpfCnpjDirective,
    DateMaskDirective,
    TimeMaskDirective,
  ],
  exports: [
    ClickOutside,
    DateInputDirective,
    DebounceDelay,
    TextFormatDirective,
    NumericoDirective,
    CpfCnpjDirective,
    DateMaskDirective,
    TimeMaskDirective,
  ],
})
export class DirectivesModule {}

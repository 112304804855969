<div class="sidenav" [class.closed]="closed">
  <ul class="menu">
    <li class="item" *ngFor="let menu of menus" [class.hidden]="!menu.visible">
      <a class="group-label" *ngIf="menu.grouping">{{ menu.id | translate }}</a>
      <a
        *ngIf="!menu.grouping && (!menu.submenus || menu.submenus.length == 0)"
        [uiSref]="menu.id"
        [uiOptions]="{ reload: true }"
        class="item-menu menu-group"
        [class.no-icon]="!menu.iconClass"
        uiSrefActive="active"
      >
        <mat-icon
          class="menu-icon"
          [ngClass]="menu.iconClass"
          *ngIf="menu.iconClass"
        ></mat-icon>
        <span class="item-menu-label">{{ menu.id | translate }}</span>
      </a>
      <a
        *ngIf="!menu.grouping && menu.submenus && menu.submenus.length > 0"
        class="item-menu menu-group"
        [class.no-icon]="!menu.iconClass"
        [class.expanded]="menu.expanded && !closed"
        [class.active]="isMenuOrChildSelected(menu)"
        (click)="menu.expanded = !menu.expanded"
      >
        <mat-icon
          class="menu-icon"
          [ngClass]="menu.iconClass"
          *ngIf="menu.iconClass"
        ></mat-icon>
        <span class="item-menu-label">{{ menu.id | translate }}</span>
        <mat-icon
          [class.hidden]="closed"
          class="fa expander-icon pull-right"
          [class.fa-angle-right]="!menu.expanded"
          [class.fa-angle-down]="menu.expanded"
        ></mat-icon>
      </a>
      <ul
        *ngIf="menu.submenus && menu.submenus.length > 0"
        class="sub-menu"
        [class.hidden]="!menu.expanded && !closed"
      >
        <li
          class="item"
          *ngFor="let submenu of menu.submenus"
          [class.hidden]="!submenu.visible"
        >
          <a
            *ngIf="!submenu.submenus || submenu.submenus.length == 0"
            class="item-menu"
            [class.no-icon]="!submenu.iconClass"
            [uiSref]="submenu.id"
            uiSrefActive="active"
            [uiOptions]="{ reload: true }"
          >
            <mat-icon
              class="submenu-icon"
              [ngClass]="submenu.iconClass"
              *ngIf="submenu.iconClass"
            ></mat-icon>
            <span>{{ submenu.id | translate }}</span>
          </a>
          <a
            *ngIf="submenu.submenus && submenu.submenus.length > 0"
            class="item-menu menu-group"
            [class.no-icon]="!submenu.iconClass"
            [class.expanded]="submenu.expanded"
            [class.active]="isMenuOrChildSelected(submenu)"
            (click)="submenu.expanded = !submenu.expanded"
          >
            <mat-icon
              class="submenu-icon"
              [ngClass]="submenu.iconClass"
              *ngIf="submenu.iconClass"
            ></mat-icon>
            <span class="item-menu-label">{{ submenu.id | translate }}</span>
            <mat-icon
              class="fa expander-icon pull-right"
              [class.fa-angle-right]="!submenu.expanded"
              [class.fa-angle-down]="submenu.expanded"
            ></mat-icon>
          </a>
          <ul
            *ngIf="submenu.submenus && submenu.submenus.length > 0"
            class="third-menu"
            [class.hidden]="!submenu.expanded"
          >
            <li
              class="item"
              *ngFor="let innermenu of submenu.submenus"
              [class.hidden]="!innermenu.visible"
            >
              <a
                class="item-menu"
                [class.no-icon]="!innermenu.iconClass"
                [uiSref]="innermenu.id"
                uiSrefActive="active"
                [uiOptions]="{ reload: true }"
              >
                <mat-icon
                  class="innermenu-icon"
                  [ngClass]="innermenu.iconClass"
                  *ngIf="innermenu.iconClass"
                ></mat-icon>
                <span>{{ innermenu.id | translate }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</div>

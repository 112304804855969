export const PT_BR = {
    periodo: 'Período',
    inicio_filter: 'Dia/Mês Inicial',
    fim_filter: 'Dia/Mês Final',
    hoje: 'Hoje',
    esta_semana: 'Esta semana (dom a sáb)',
    proxima_semana: 'Próxima semana (dom a sáb)',
    este_mes: 'Este mês',
    proximo_mes: 'Próximo mês',
    personalizado: 'Personalizado'
}

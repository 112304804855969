import { Component } from '@angular/core'
import { TranslateStaticLoader } from '../../../core/translate.loader'
import { PT_BR } from '../i18n/pt-br'
import { EN_US } from '../i18n/en-us'
import { FormControl } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'

/**
 * Componente de dialog para exibir mensagem de confirmação de ação
 */
@Component({
  selector: 'rejeitar-dialog',
  templateUrl: './rejeitar-dialog.component.html',
  styleUrls: ['./rejeitar-dialog.component.scss'],
})
export class RejeitarDialogComponent {
  motivoFormControl: FormControl = new FormControl('')

  constructor(
    private translateLoader: TranslateStaticLoader,
    private dialogRef: MatDialogRef<RejeitarDialogComponent>
  ) {
    this.translateLoader.load('ptBR', PT_BR)
    this.translateLoader.load('enUS', EN_US)
  }

  rejeitar() {
    this.motivoFormControl.markAsTouched()
    if (this.motivoFormControl.invalid) {
      return
    }

    this.dialogRef.close({
      dsMotivo: this.motivoFormControl.value,
    })
  }
}

/**
 * @whatItDoes Disponibiliza um objeto para manipulação de um parâmetro de pesquisa.
 *
 * @description Objeto responsável por manipular chaves e valores utilizados
 * em uma busca parametrizada. As chaves informadas para a busca permanecem no
 * objeto até serem explicitamente removidas.
 *
 */
export class SearchParam {
  /**
   * Source utilizado para montar a query da busca.
   *
   * @type {string}
   */
  private source: string = '?search='

  /**
   * Array de chaves e valores utilizados na busca.
   *
   * @type {{}}
   */
  private params: {
    [key: string]: { key: string; ope: string; value: any }
  } = {}

  /**
   * Salva um novo parâmetro com chave, valor e operação.
   *
   * @param {string} key
   * @param {string} ope
   * @param value
   * @returns {SearchParam}
   */
  put(key: string, ope: string, value: any) {
    this.params[key + ope] = { key: key, ope: ope, value: value }
    return this
  }

  /**
   * Remove um parâmetro da busca.
   *
   * @param {string} key
   * @returns {SearchParam}
   */
  remove(key: string) {
    delete this.params[key]
    return this
  }

  /**
   * Monta a string final que será utilizada na busca.
   *
   * @returns {string}
   */
  build() {
    let i = 0
    let source = this.source

    for (let entry in this.params) {
      let param =
        this.params[entry].key +
        this.params[entry].ope +
        this.params[entry].value
      source += i === 0 ? `${param}` : `;${param}`
      i++
    }

    return source
  }
}

export const MENUS = [
  {
    id: 'dashboard',
    description: 'Home',
    iconClass: 'fa fa-home',
    visible: true,
  },
  {
    id: 'analise_missionarios',
    description: 'Análise de Missionários',
    iconClass: 'fa fa-users',
    visible: true,
  },
]

import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http'
import { JwtHelperService } from '@auth0/angular-jwt'
import { User, Perfil } from './user'
import { environment } from '../../../environments/environment'
import { Subject, Observable } from 'rxjs'

@Injectable()
export class OAuthService {
  loginUrl: string

  private currentUserSource = new Subject<any>()

  currentUser$ = this.currentUserSource.asObservable()

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService) {
    this.loginUrl = `${environment.apiUrl}/login/admin`
  }

  login(email: string, senha: string) {
    return this.http.post(this.loginUrl, {
      dsEmail: email,
      dsSenha: senha,
    })
  }

  currentUser(): User {
    const token: any = this.jwtHelper.decodeToken()

    return {
      dsNome: token.preferred_username,
      dsEmail: token.sub,
      tpPerfil: token.groups[0],
    }
  }

  updatePerfil(perfil: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/admin/perfil`, perfil)
  }

  currentUserChanged() {
    this.currentUserSource.next()
  }
}

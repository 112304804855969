<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>{{ message }}</mat-dialog-content>
<mat-dialog-actions class="actions">
  <button mat-button mat-dialog-close class="cancel-btn">
    {{ cancelLabel }}
  </button>
  <button mat-button [mat-dialog-close]="true" class="confirm-btn">
    {{ confirmLabel }}
  </button>
</mat-dialog-actions>

import { EN_US } from './i18n/en-us'
import { PT_BR } from './i18n/pt-br'
import { Subscription } from 'rxjs'
import { MenuService } from './menu.service'
import { Component } from '@angular/core'
import { StateDeclaration } from '@uirouter/angular'
import { RouteService } from '../../core/route.service'
import { TranslateStaticLoader } from '../../core/translate.loader'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  public selected: any

  public closed: boolean = false

  public menus: any = []

  private subscription: Subscription

  constructor(
    private menuService: MenuService,
    private routeService: RouteService,
    private translateLoader: TranslateStaticLoader
  ) {
    this.subscription = this.menuService.toggleMenu$.subscribe(open => {
      this.closed = !open
    })

    this.routeService.routeChanged$.subscribe(event => {
      this.routeActivated(event.to())
    })

    this.menus = menuService.getMenus()

    this.translateLoader.load('ptBR', PT_BR)
    this.translateLoader.load('enUS', EN_US)

    this.closed = !this.menuService.menuOpen
  }

  private routeActivated(state: StateDeclaration) {
    const parentName: any = state.parent
    this.selected = this.findInMenuTree(state.name, parentName, this.menus)

    this.menus.forEach(menu => {
      let found = this.findInChildren(menu)
      if (found) {
        found.expanded = true
      }
    })
  }

  private findInChildren(menu) {
    if (menu.submenus) {
      let found = menu.submenus.find(submenu => {
        return submenu == this.selected || this.findInChildren(submenu)
      })

      if (found) {
        menu.expanded = true
      }

      return found
    }

    return undefined
  }

  private findInMenuTree(
    name: string,
    parentName: string,
    menuTree: any[]
  ): any {
    for (let menu of menuTree) {
      if (menu.id === name || menu.id === parentName) {
        return menu
      } else if (menu.submenus) {
        let m = this.findInMenuTree(name, parentName, menu.submenus)
        if (m) {
          return m
        }
      }
    }

    return undefined
  }

  public toogle() {
    this.closed = !this.closed
  }

  public isMenuOrChildSelected(menu) {
    return menu == this.selected || this.isChildSelected(menu.submenus || [])
  }

  private isChildSelected(menus: any[]) {
    return menus.find(menu => {
      return this.isMenuOrChildSelected(menu)
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}

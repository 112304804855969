import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'astutus-combobox-item',
  templateUrl: './astutus-combobox-item.component.html',
  styleUrls: ['./astutus-combobox-item.component.scss'],
})
export class AstutusComboboxItem {
  @Input()
  public item: Object

  @Input()
  public label: string

  @Output()
  public select: EventEmitter<any> = new EventEmitter()

  constructor() {}

  public onClickSelect() {
    this.select.emit(this.item)
  }
}

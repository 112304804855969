import { NgModule } from '@angular/core'
import { UIRouterModule } from '@uirouter/angular'

import { LoginComponent } from './login.component'
import { SharedModule } from '../../shared/shared.module'

@NgModule({
  imports: [SharedModule, UIRouterModule],
  declarations: [LoginComponent],
})
export class LoginModule {}

<div class="wrapper">
  <section class="parallax-background forgot-password">
    <div id="login-v2-form-wrapper" class="flex-scrollable mat-whiteframe-8dp">
      <div id="login-v2-form">
        <div class="logo">
          <img src="assets/logo-ad-gentes.png" alt="Logo" />
        </div>

        <form
          class="form-login"
          (ngSubmit)="resetPassword()"
          #f="ngForm"
          novalidate
        >
          <mat-form-field class="mat-block">
            <mat-icon
              matPrefix
              class="fa fa-key font-grey-salt icon-pesquisa"
            ></mat-icon>
            <input
              matInput
              type="password"
              name="password"
              placeholder="{{ 'new_password' | translate }}"
              required
              [formControl]="passwordFormControl"
              [(ngModel)]="model.password"
            />
            <mat-error *ngIf="passwordFormControl.hasError('required')">
              {{ 'required_new_password' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mat-block">
            <mat-icon
              matPrefix
              class="fa fa-key font-grey-salt icon-pesquisa"
            ></mat-icon>
            <input
              matInput
              type="password"
              name="repeatepassword"
              placeholder="{{ 'rewrite_password' | translate }}"
              required
              [formControl]="repeateFormControl"
              [(ngModel)]="model.repeatepassword"
            />
            <mat-error *ngIf="repeateFormControl.hasError('required')">
              {{ 'required_rewrite_password' | translate }}
            </mat-error>
          </mat-form-field>

          <button mat-button class="reset-button red-intense">
            {{ 'reset_button' | translate }}
          </button>
        </form>
      </div>
    </div>
  </section>
</div>

import { Injectable } from '@angular/core'
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { StateService } from '@uirouter/core'
import { catchError } from 'rxjs/operators'

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private stateService: StateService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(response => {
        if (response instanceof HttpErrorResponse) {
          const error: HttpErrorResponse = response
          if (error.status == 401) {
            this.stateService.go('login')
          }
        }

        return throwError(response)
      })
    )
  }
}

import { CustomDateAdapter } from './core/date.adapter'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateModule } from '@ngx-translate/core'
import { NgModule } from '@angular/core'
import { JwtModule } from '@auth0/angular-jwt'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app.routing.module'
import { CoreModule } from './core/core.module'
import { LayoutModule } from './layout/layout.module'

import { SharedModule } from './shared/shared.module'
import { DateAdapter } from '@angular/material/core'
import { AppLoadModule } from './app.load.module'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { UnauthorizedInterceptor } from './core/interceptors/unauthorized'
import { LoadingInterceptor } from './core/interceptors/loading'
import { PagesModule } from './pages/pages.module'
import { tokenLoader } from './core/oauth/token.service'

import 'hammerjs'
import { environment } from '../environments/environment'

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppLoadModule,
    CoreModule,
    LayoutModule,
    SharedModule.forRoot(),
    PagesModule,
    TranslateModule.forRoot(),
    AppRoutingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenLoader,
        whitelistedDomains: [environment.apiUrl.split('//')[1]],
      },
    }),
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

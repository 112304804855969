import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core'

@Component({
  selector: 'astutus-combobox-overlay',
  templateUrl: './astutus-combobox-overlay.component.html',
  styleUrls: ['./astutus-combobox-overlay.component.scss'],
})
export class AstutusComboboxOverlay {
  @ViewChild('scroller')
  public scroller: any

  @Input()
  public items: Array<any>

  @Input('position-target')
  public positionTarget: any

  @Input('selected-label')
  public selectedLabel: string

  @Input()
  public selected: Object

  @Output('selected-change')
  public selectedChange: EventEmitter<any> = new EventEmitter()

  public showProgress: boolean = false

  private interval

  //---

  private alignedAbove: boolean

  private translateX: number

  private translateY: number

  constructor(private elementRef: ElementRef) {}

  public onSelectItem(item) {
    this.selected = item

    this.selectedChange.emit(item)
  }

  public getLabel(item: Object) {
    return this.selectedLabel ? eval('item.' + this.selectedLabel) : item
  }

  public isSelected(selected: Object, item: Object) {
    return this.equals(selected, item)
  }

  private equals(x, y) {
    if (x === y) return true
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) return false
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) return false
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x) {
      if (!x.hasOwnProperty(p)) continue
      // other properties were tested using x.constructor === y.constructor

      if (!y.hasOwnProperty(p)) return false
      // allows to compare x[ p ] and y[ p ] when set to undefined

      if (x[p] === y[p]) continue
      // if they have the same strict value or identity then they are equal

      if (typeof x[p] !== 'object') return false
      // Numbers, Strings, Functions, Booleans must be strictly equal

      if (!this.equals(x[p], y[p])) return false
      // Objects and Arrays must be tested recursively
    }

    for (p in y) {
      if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false
      // allows x[ p ] to be set to undefined
    }

    return true
  }

  public moveTo(target, add) {
    target.appendChild(this.elementRef.nativeElement)

    if (add) {
      window.addEventListener('scroll', e => this.setPosition(e))

      this.setPosition()

      this.interval = setInterval(() => this.setPosition(), 500)
    } else {
      clearInterval(this.interval)
      window.removeEventListener('scroll', e => this.setPosition(e))
    }
  }

  private setPosition(e?) {
    let targetRect = this.positionTarget.getBoundingClientRect()
    this.alignedAbove = this.shouldAlignAbove()

    let overlayRect = this.elementRef.nativeElement.getBoundingClientRect()
    this.translateX =
      targetRect.left - overlayRect.left + (this.translateX || 0)
    this.translateY =
      targetRect.top -
      overlayRect.top +
      (this.translateY || 0) +
      this.verticalOffset(overlayRect, targetRect)

    var _devicePixelRatio = window.devicePixelRatio || 1
    this.translateX =
      Math.round(this.translateX * _devicePixelRatio) / _devicePixelRatio
    this.translateY =
      Math.round(this.translateY * _devicePixelRatio) / _devicePixelRatio

    if (!this.alignedAbove) {
      this.translateY = this.translateY - 20
    }

    this.translate3d(
      this.translateX + 'px',
      this.translateY + 'px',
      this.elementRef.nativeElement
    )

    this.elementRef.nativeElement.style.width =
      this.positionTarget.clientWidth + 'px'
  }

  private translate3d(x, y, element) {
    element.style.transform = 'translate(' + x + ',' + y + ')'
    element.style.OTransform = 'translate(' + x + ',' + y + ')' // Opera
    element.style.msTransform = 'translate(' + x + ',' + y + ')' // IE 9
    element.style.MozTransform = 'translate(' + x + ',' + y + ')' // Firefox
    element.style.WebkitTransform = 'translate(' + x + ',' + y + ')' // Safari and Chrome
  }

  private verticalOffset(overlayRect, targetRect) {
    if (this.alignedAbove) {
      return -overlayRect.height
    } else {
      return targetRect.height + 2
    }
  }

  private shouldAlignAbove() {
    var spaceBelow =
      (window.innerHeight -
        this.positionTarget.getBoundingClientRect().bottom -
        Math.min(document.body.scrollTop, 0)) /
      window.innerHeight

    return spaceBelow < 0.3
  }
}

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'
import { UIRouter, StateService } from '@uirouter/angular'
import { TranslateStaticLoader } from '../../../core/translate.loader'
import { PT_BR } from '../i18n/pt-br'
import { EN_US } from '../i18n/en-us'
import { ErrorResponse } from '../../../shared/error-handler/error-response'
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component'
import { AnaliseMissionariosService } from '../analise-missionarios.service'
import { DateAdapter } from '@angular/material/core'
import { FabOption } from 'src/app/shared/astutus-fab/fab.option'
import { RejeitarDialogComponent } from '../rejeitar-dialog/rejeitar-dialog.component'
import { TipoAnalise } from '../missionario.model'

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss'],
})
export class FormularioComponent implements OnInit {
  saving: boolean = false
  missionario: any
  projetos: any[]
  fotoPerfil: string = 'assets/person.png'

  nomeFormControl = new FormControl({ value: '', disabled: true })
  emailFormControl = new FormControl({ value: '', disabled: true })
  idadeFormControl = new FormControl({ value: '', disabled: true })
  statusCadastroFormControl = new FormControl({ value: '', disabled: true })
  dtCadastroFormControl = new FormControl({ value: '', disabled: true })
  sexoFormControl = new FormControl({ value: '', disabled: true })
  instituicaoFormControl = new FormControl({ value: '', disabled: true })
  arquidioceseFormControl = new FormControl({ value: '', disabled: true })
  paisFormControl = new FormControl({ value: '', disabled: true })
  instagramFormControl = new FormControl({ value: '', disabled: true })
  facebookFormControl = new FormControl({ value: '', disabled: true })
  whatsappFormControl = new FormControl({ value: '', disabled: true })
  twitterFormControl = new FormControl({ value: '', disabled: true })
  emailContatoFormControl = new FormControl({ value: '', disabled: true })

  form: FormGroup = new FormGroup({
    dsNome: this.nomeFormControl,
    dsEmail: this.emailFormControl,
    nrIdade: this.idadeFormControl,
    tpStatus: this.statusCadastroFormControl,
    dtCadastro: this.dtCadastroFormControl,
    tpSexo: this.sexoFormControl,
    dsRegInstituicao: this.instituicaoFormControl,
    dsDiocese: this.arquidioceseFormControl,
    dsPais: this.paisFormControl,
    dsUrlInstagram: this.instagramFormControl,
    dsUrlTwitter: this.twitterFormControl,
    dsUrlFacebook: this.facebookFormControl,
    dsNrWhatsapp: this.whatsappFormControl,
    dsEmailContato: this.emailContatoFormControl,
  })

  aproveOption: FabOption = {
    class: 'fa fa-check',
    color: 'secundary',
    tooltip: 'Aprovar cadastro',
    onClick: () => {
      this.approveFlow()
    },
  }
  rejectOption: FabOption = {
    class: 'fa fa-times',
    color: 'secundary',
    tooltip: 'Não aprovar cadastro',
    onClick: () => {
      this.rejectFlow()
    },
  }

  fabOptions: FabOption = {
    class: 'fa fa-bars',
    color: 'primary',
    tooltip: 'Opções',
    items: [this.rejectOption, this.aproveOption],
  }

  constructor(
    private snackBar: MatSnackBar,
    private translateLoader: TranslateStaticLoader,
    private translate: TranslateService,
    private uiRouter: UIRouter,
    public dialog: MatDialog,
    private errorResponse: ErrorResponse,
    private service: AnaliseMissionariosService,
    private dateFormatter: DateAdapter<Date>,
    private stateService: StateService
  ) {
    this.translateLoader.load('ptBR', PT_BR)
    this.translateLoader.load('enUS', EN_US)

    this.fabOptions.tooltip = this.translate.instant('opcoes')
    this.aproveOption.tooltip = this.translate.instant('aprovar')
    this.rejectOption.tooltip = this.translate.instant('rejeitar')
  }

  ngOnInit() {
    if (this.uiRouter.globals.params.id) {
      this.find(this.uiRouter.globals.params.id)
    }
  }

  find(id: number) {
    this.service.find(id).subscribe(
      data => {
        const value: any = data
        this.missionario = value.data

        const missionarioModel: any = {}

        missionarioModel.dsNome = this.missionario.regUsuario.dsNome
        missionarioModel.dsEmail = this.missionario.regUsuario.dsEmail
        missionarioModel.nrIdade = this.getIdadeMissionario()
        missionarioModel.dsRegInstituicao = this.missionario.regTipoInstituicao?.dsTipoInstituicao
        missionarioModel.dsPais = this.missionario.regPais.dsNome
        this.fotoPerfil =
          this.missionario.regUsuario.dsFotoPerfil || this.fotoPerfil

        missionarioModel.tpSexo = this.translate.instant(
          `sexo_${this.missionario.tpSexo}`
        )
        missionarioModel.tpStatus = this.getStatusMissionario(
          this.missionario.tpStatus
        )
        missionarioModel.dtCadastro = this.dateFormatter.format(
          new Date(this.missionario.regUsuario.dhInsert),
          {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }
        )

        if (this.missionario.regTipoInstituicao?.tipoCampo === 1) {
          missionarioModel.dsDiocese = this.missionario.regTipoInstituicaoOpcao?.dsDescricaoOpcao
        } else {
          missionarioModel.dsDiocese = this.missionario.dsValorTipoInstituicao
        }
        missionarioModel.dsDiocese = missionarioModel.dsDiocese || ''

        let contato: any = {}
        if (this.missionario.contatos && this.missionario.contatos.length) {
          contato = this.missionario.contatos[0]
        }
        missionarioModel.dsUrlInstagram = contato.dsUrlInstagram || ''
        missionarioModel.dsUrlTwitter = contato.dsUrlTwitter || ''
        missionarioModel.dsUrlFacebook = contato.dsUrlFacebook || ''
        missionarioModel.dsEmailContato = contato.dsEmailContato || ''

        if (contato.dsNrWhatsapp) {
          missionarioModel.dsNrWhatsapp =
            '+' +
            contato.dsNrWhatsapp.substring(
              0,
              contato.dsNrWhatsapp.indexOf('-')
            ) +
            ' ' +
            contato.dsNrWhatsapp.substring(
              contato.dsNrWhatsapp.indexOf('-') + 1
            )
        } else {
          missionarioModel.dsNrWhatsapp = ''
        }

        this.form.setValue(missionarioModel)

        this.service.getProjetos(id).subscribe(
          data =>
            (this.projetos = (<any>data).data.map(item => item.regProjeto)),
          error => this.showErrorLoadingProjects()
        )
      },
      error => this.errorResponse.showMessage(error)
    )
  }

  getIdadeMissionario(): string {
    return `${new Date().getFullYear() -
      new Date(
        this.missionario.dtNascimento
      ).getFullYear()} ${this.translate.instant('anos')}`
  }

  getStatusMissionario(tpStatus: number): any {
    switch (tpStatus) {
      case 0:
        return this.translate.instant('bispo')
      case 1:
        return this.translate.instant('padre')
      case 2:
        return this.translate.instant('diacono')
      case 3:
        return this.translate.instant('leigo')
      case 4:
        return this.translate.instant('religioso')
      case 5:
        return this.translate.instant('seminarista')
      case 6:
        return this.translate.instant('outro')
    }
  }

  update() {
    this.saving = true

    this.service.update(null).subscribe(
      (data: any) => {
        this.saving = false
        this.snackBar.open(this.translate.instant('update_success'), '', {
          duration: 5000,
        })
      },
      error => {
        this.saving = false
        this.errorResponse.showMessage(error)
      }
    )
  }

  private approveFlow() {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: this.translate.instant('aprovar'),
        message: this.translate.instant('confirma_aprovacao'),
        confirmLabel: this.translate.instant('sim'),
        cancelLabel: this.translate.instant('nao'),
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saving = true
        this.service
          .analiseMissionario({
            idRegMissionario: this.missionario.id,
            tpStatus: TipoAnalise.APROVADO,
          })
          .subscribe(
            () =>
              this.analiseSaved(this.translate.instant('cadastro_aprovado')),
            error => this.errorResponse.showMessage(error),
            () => (this.saving = false)
          )
      }
    })
  }

  private rejectFlow() {
    let dialogRef = this.dialog.open(RejeitarDialogComponent)

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saving = true
        this.service
          .analiseMissionario({
            idRegMissionario: this.missionario.id,
            tpStatus: TipoAnalise.REJEITADO,
            dsMotivo: result.dsMotivo,
          })
          .subscribe(
            () =>
              this.analiseSaved(this.translate.instant('cadastro_rejeitado')),
            error => this.errorResponse.showMessage(error),
            () => (this.saving = false)
          )
      } else {
        this.saving = false
      }
    })
  }

  private analiseSaved(message: string) {
    {
      this.snackBar.open(message, 'OK', {
        duration: 5000,
      })
      this.stateService.go('^')
    }
  }

  private showErrorLoadingProjects() {
    this.snackBar.open(this.translate.instant('error_projetos'), 'OK', {
      duration: 5000,
    })
  }
}

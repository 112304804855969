import { Component, Input, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

/**
 * Componente de dialog para exibir mensagem de confirmação de ação
 */
@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  @Input()
  message: string

  @Input()
  title: string

  @Input()
  cancelLabel: string

  @Input()
  confirmLabel: string

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title
    this.message = data.message
    this.cancelLabel = data.cancelLabel
    this.confirmLabel = data.confirmLabel
  }
}

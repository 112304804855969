<div class="periodo-container">
  <astutus-combobox
    class="filter-type"
    label="{{ 'periodo' | translate }}"
    selected-label="descricao"
    [(selected)]="periodo"
    [items]="periodos"
    (selectedChange)="changePeriodo()"
  >
  </astutus-combobox>

  <div *ngIf="customDate">
    <mat-form-field class="inicio-filter">
      <input
        matInput
        placeholder="{{ 'inicio_filter' | translate }}"
        textFormat="99/99"
        [(ngModel)]="periodo.periodoInicial"
        debounceDelay
        (debounce)="dataInicialChanged()"
        (keyup.enter)="dataInicialChanged()"
      />
    </mat-form-field>

    <mat-form-field class="fim-filter">
      <input
        matInput
        placeholder="{{ 'fim_filter' | translate }}"
        textFormat="99/99"
        [(ngModel)]="periodo.periodoFinal"
        debounceDelay
        (debounce)="dataFinalChanged()"
        (keyup.enter)="dataFinalChanged()"
      />
    </mat-form-field>
  </div>
</div>

<section>
  <div class="section-title">
    <h1>Minha Conta</h1>
  </div>
  <div class="content-section">
    <mat-card>
      <div class="form-container">
        <form #f="ngForm" [formGroup]="form" novalidate>
          <mat-form-field>
            <input
              matInput
              name="dsEmail"
              placeholder="{{ 'email' | translate }}"
              [formControl]="emailFormControl"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              name="dsNome"
              required
              placeholder="{{ 'name' | translate }}"
              [formControl]="nameFormControl"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              type="password"
              name="current_password"
              required
              placeholder="{{ 'current_password' | translate }}"
              [formControl]="currentPasswordFormControl"
            />

            <mat-error *ngIf="currentPasswordFormControl.hasError('required')">
              {{ 'current_password_required' | translate }}
            </mat-error>
            <mat-error *ngIf="currentPasswordFormControl.hasError('minlength')">
              {{ 'min_length_3' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              type="password"
              name="new_password"
              required
              placeholder="{{ 'new_password' | translate }}"
              [formControl]="newPasswordFormControl"
            />

            <mat-error *ngIf="newPasswordFormControl.hasError('required')">
              {{ 'new_password_required' | translate }}
            </mat-error>

            <mat-error *ngIf="newPasswordFormControl.hasError('isEqual')">
              {{ 'new_password_equal' | translate }}
            </mat-error>
            <mat-error *ngIf="newPasswordFormControl.hasError('minlength')">
              {{ 'min_length_3' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              type="password"
              name="repeat_new_password"
              required
              placeholder="{{ 'repeat_new_password' | translate }}"
              [formControl]="repeatNewPasswordFormControl"
            />

            <mat-error
              *ngIf="repeatNewPasswordFormControl.hasError('required')"
            >
              {{ 'repeat_new_password_required' | translate }}
            </mat-error>
            <mat-error
              *ngIf="repeatNewPasswordFormControl.hasError('isntEqual')"
            >
              {{ 'repeat_new_password_isntequal' | translate }}
            </mat-error>
            <mat-error
              *ngIf="repeatNewPasswordFormControl.hasError('minlength')"
            >
              {{ 'min_length_3' | translate }}
            </mat-error>
          </mat-form-field>

          <div class="button-container fixed-bottom">
            <button
              mat-fab
              color="primary"
              type="button"
              [disabled]="saving"
              (click)="save()"
            >
              <mat-icon class="fa fa-check"></mat-icon>
            </button>
          </div>
        </form>
      </div>
    </mat-card>
  </div>
</section>

import { UIRouterModule, Transition } from '@uirouter/angular'
import { NgModule } from '@angular/core'
import { PostPublicoComponent } from './post-publico.component'
import { PostPublicoService } from './post-publico.service'

const states = [
  {
    name: 'post_publico',
    url: '/post/public/{uuid}',
    views: {
      'page-content@': {
        component: PostPublicoComponent,
      },
    },
    resolve: [
      {
        token: 'post',
        deps: [PostPublicoService, Transition],
        resolveFn: (service, trans) =>
          service.getPublicPost(trans.params().uuid),
      },
    ],
  },
]

@NgModule({
  imports: [UIRouterModule.forChild({ states: states })],
  exports: [UIRouterModule],
})
export class PostPublicoRoutingModule {}

import { UsuarioModule } from '../usuario/usuario.module'
import { SharedModule } from '../../shared/shared.module'
import { HeaderComponent } from './header.component'
import { NgModule } from '@angular/core'

@NgModule({
  imports: [SharedModule, UsuarioModule],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}

import { NgModule } from '@angular/core'
import { MatPaginatorIntl } from '@angular/material/paginator'

import { SharedModule } from '../../shared/shared.module'
import { AnaliseMissionariosComponent } from './analise-missionarios.component'
import { AnaliseMissionariosRoutingModule } from './analise-missionarios.routing.module'

import { CustomPaginatorIntl } from '../../shared/paginator/custom-paginator-intl'
import { AnaliseMissionariosService } from './analise-missionarios.service'
import { FormularioComponent } from './formulario/formulario.component'
import { RejeitarDialogComponent } from './rejeitar-dialog/rejeitar-dialog.component'

@NgModule({
  imports: [SharedModule, AnaliseMissionariosRoutingModule],
  providers: [
    AnaliseMissionariosService,
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
  ],
  declarations: [
    AnaliseMissionariosComponent,
    FormularioComponent,
    RejeitarDialogComponent,
  ],
  entryComponents: [RejeitarDialogComponent],
})
export class AnaliseMissionariosModule {}

import { TransitionService } from '@uirouter/angular'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { JwtHelperService } from '@auth0/angular-jwt'
import { RouteService } from './core/route.service'
import { OAuthService } from './core/oauth/oauth.service'
import { Perfil } from './core/oauth/user'

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
  providers: [RouteService],
})
export class AppLoadModule {
  // This matches a transition coming from any state and going to any state that has `data.authRequired`
  // set to a truthy value.
  private match = {
    to: function(state) {
      return state.data != null && state.data.authRequired === true
    },
  }

  constructor(
    private transitionService: TransitionService,
    private tokenService: JwtHelperService,
    private oauthService: OAuthService
  ) {
    this.checkToken()
  }

  private checkToken() {
    this.transitionService.onStart(this.match, transition => {
      if (
        this.tokenService.isTokenExpired() ||
        this.oauthService.currentUser()?.tpPerfil != Perfil.ADMINISTRADOR
      ) {
        return transition.router.stateService.target('login')
      }

      return true
    })
  }
}

<section>
  <div class="section-title">
    <h1>{{ 'analise_missionarios_title' | translate }}</h1>
  </div>

  <mat-card>
    <mat-card-title class="has-border-bottom">
      {{ 'filter_title' | translate }}
    </mat-card-title>
    <mat-card-content class="content-filter">
      <mat-form-field>
        <mat-label>{{ 'cadastro' | translate }}</mat-label>
        <mat-select [formControl]="cadastroControl">
          <mat-option *ngFor="let tipo of tipoCadastroList" [value]="tipo">
            {{ tipo | lowercase | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'tipo_instituicao' | translate }}</mat-label>
        <mat-select [formControl]="instituicaoControl">
          <mat-option *ngFor="let tipo of tipoInstituicaoList" [value]="tipo">
            {{ tipo.dsTipoInstituicao }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'status_missionario' | translate }}</mat-label>
        <mat-select [formControl]="statusControl">
          <mat-option *ngFor="let status of tipoStatusList" [value]="status">
            {{ status | lowercase | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
  <mat-card class="grid">
    <mat-card-title class="has-border-bottom">
      {{ 'missionarios_title' | translate }}
    </mat-card-title>
    <mat-card-content>
      <mat-table
        #table
        [dataSource]="tableDataSource"
        matSort
        (matSortChange)="loadData($event)"
        click-outside
        (clickOutside)="onSelect(undefined)"
      >
        <ng-container matColumnDef="regUsuario.dsNome">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="flex-3 cell-padding-right"
            >{{ 'nome' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="flex-3 cell-padding-right">
            {{ row.regUsuario.dsNome }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="regUsuario.dsEmail">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="flex-3 cell-padding-right"
            >{{ 'email' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="flex-3 cell-padding-right">
            {{ row.regUsuario.dsEmail }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="regUsuario.dhInsert">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="cell-date cell-padding-right"
            >{{ 'data_cadastro' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="cell-date cell-padding-right">
            {{ row.regUsuario.dhInsert | dateFormatter }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tpStatus">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="cell-status-missionario cell-padding-right"
            >{{ 'status_missionario' | translate }}</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row"
            class="cell-status-missionario cell-padding-right"
          >
            {{ row.tpStatus | lowercase | translate }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="regTipoInstituicao.dsTipoInstituicao">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="flex-2">{{
            'tipo_instituicao' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let row" class="flex-2">
            {{ row.regTipoInstituicao.dsTipoInstituicao }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{ selected: selected === row }"
          (click)="onSelect(row)"
        ></mat-row>
      </mat-table>

      <mat-paginator
        #paginator
        class="justify-left"
        [length]="paginatorData.length"
        [pageIndex]="paginatorData.pageIndex"
        [pageSize]="paginatorData.pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="onPageChange($event)"
      >
      </mat-paginator>
    </mat-card-content>

    <div class="button-container fixed-bottom">
      <a [disabled]="!selected" mat-fab color="primary" (click)="openDetails()">
        <mat-icon>arrow_forward</mat-icon>
      </a>
    </div>
  </mat-card>
</section>

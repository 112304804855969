import { FiltroPeriodo } from './filtro-periodo.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatInputModule } from '@angular/material/input'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { TranslateModule } from '@ngx-translate/core'
import { AstutusComboboxModule } from '../astutus-combobox/astutus-combobox.module'
import { FormsModule } from '@angular/forms'
import { DirectivesModule } from '../directives/directives.module'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    TranslateModule,
    AstutusComboboxModule,
    MatDatepickerModule,
    DirectivesModule,
    MatSnackBarModule,
  ],
  declarations: [FiltroPeriodo],
  exports: [FiltroPeriodo],
})
export class FiltroPeriodoModule {}

import { AbstractFormatter } from '../formatter/abstract-formatter'
import { ControlValueAccessor } from '@angular/forms'
import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core'
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'

/**
 * Diretiva para campos de data
 */
@Directive({
  selector: '[dateInputFormatter]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateInputDirective,
      multi: true,
    },
  ],
})
export class DateInputDirective extends AbstractFormatter
  implements ControlValueAccessor {
  private mask: string = '99/99/9999'

  onTouched: any
  onChange: any

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private dateAdapter: DateAdapter<Date>
  ) {
    super()
    this.onChange = () => {}
    this.onTouched = () => {}
  }

  writeValue(value: any): void {
    let formatedValue = this.formatDate(value)
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'value',
      formatedValue
    )
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  @HostListener('input', ['$event'])
  onInput($event: any) {
    let clearedValue = this.clearValue($event.target.value)
    let formatedValue = this.formatValue(clearedValue, this.mask)

    $event.target.value = formatedValue
    let date: Date = this.parseDate(formatedValue)

    this.onChange(date)
  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    if ($event.target.value.length !== this.mask.length) {
      $event.target.value = ''
      this.onChange(undefined)
    }

    this.onTouched()
  }

  private parseDate(str: string): Date | undefined {
    return this.dateAdapter.parse(str, undefined)
  }

  private formatDate(date: Date | undefined): string {
    if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
      return ''
    }

    let dateFormat: any = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }
    return this.dateAdapter.format(date, dateFormat)
  }
}

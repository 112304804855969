import { NgModule } from '@angular/core'

import { SharedModule } from '../../shared/shared.module'
import { ContaComponent } from './conta.component'
import { ContaRoutingModule } from './conta.routing.module'

@NgModule({
  imports: [SharedModule, ContaRoutingModule],
  providers: [],
  declarations: [ContaComponent],
})
export class ContaModule {}

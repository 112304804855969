import { Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NativeDateAdapter, DateAdapter } from '@angular/material/core'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    translate: TranslateService,
    private dateAdapter: DateAdapter<NativeDateAdapter>
  ) {
    const ptBR: string = 'ptBR'

    translate.setDefaultLang(ptBR)

    let lang: string = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : ptBR
    translate.use(lang)

    this.dateAdapter.setLocale(lang.substring(0, 2) + '-' + lang.substring(2))

    localStorage.setItem('lang', lang)
  }
}

import { Component, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'

import { OAuthService } from '../../core/oauth/oauth.service'
import { PT_BR } from './i18n/pt-br'
import { EN_US } from './i18n/en-us'
import { TranslateStaticLoader } from '../../core/translate.loader'

@Component({
  selector: 'esqueceu-senha',
  templateUrl: './esqueceu-senha.component.html',
  styleUrls: ['./esqueceu-senha.component.scss'],
})
export class EsqueceuSenhaComponent implements OnInit {
  model: any = {}
  loading = false
  requestSuccess = false

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ])

  constructor(
    private oauth: OAuthService,
    private snackBar: MatSnackBar,
    private translateLoader: TranslateStaticLoader
  ) {
    this.translateLoader.load('ptBR', PT_BR)
    this.translateLoader.load('enUS', EN_US)
  }

  ngOnInit() {}

  recover() {
    this.loading = true
    if (this.emailFormControl.valid) {
      // this.oauth.recoverPassword(this.model.email).subscribe(
      //   data => this.onRecoverSuccess(data),
      //   error => this.onRecoverError(error)
      // )
    }
  }

  onRecoverSuccess(data) {
    this.requestSuccess = true
  }

  onRecoverError(error) {
    this.snackBar.open('Usuário não localizado!', 'Ok', {
      duration: 5000,
    })

    this.loading = false
  }
}

import { Injectable } from "@angular/core";

@Injectable()
export class DownloadService {
    
    public download(fileName, content) {
        this.saveByteArray(fileName, this.base64ToArrayBuffer(content));
    }
        
    /**
     * Transforma base64 em bytes;
     */
    private base64ToArrayBuffer(base64) {
        const binaryString =  window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++)        {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    /**
     * Realiza o download do array de bytes passado.
     */
    private saveByteArray(fileName, byte) {
        const timeNow = new Date();
        const month = timeNow.getMonth() + 1;

        const blob = new Blob([byte], {type: this.getType(fileName)});
        
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url; 
        link.download = fileName;
        
        document.body.appendChild(link);
        
        link.click();
        
        setTimeout(function() {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
        
    /**
     * Retorna o mimetype conforme o tipo do arquivo (pdf, xls ou xlsx)
     */
    private getType(fileName) {
        const type = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (type === 'pdf') {
            return 'application/pdf';
        }
        if (type === 'xls') {
            return 'application/vnd.ms-excel';
        }
        if (type === 'xlsx') {
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        }
        
        return "octet/stream";
    }

}

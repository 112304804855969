import { Component } from '@angular/core'
import { StateDeclaration, StateService } from '@uirouter/angular'

interface IBreadbcrumb {
  label: string
  params?: any
  state: string
}

const ROUTE_DATA_BREADCRUMB: string = 'breadcrumb'

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  public breadcrumbs: IBreadbcrumb[]

  constructor(private stateService: StateService) {
    this.breadcrumbs = this.buildBreadcrumbs(this.stateService.current)
  }

  private buildBreadcrumbs(state: StateDeclaration) {
    let breadcrumbs = []

    if (state.data && state.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
      breadcrumbs.push(this.getBreadcrumb(state))
    }

    let parent: StateDeclaration = state.parent
      ? this.stateService.get(state.parent)
      : undefined

    while (parent) {
      if (parent.data && parent.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        breadcrumbs.push(this.getBreadcrumb(parent))
      }
      parent = parent.parent ? this.stateService.get(parent.parent) : undefined
    }

    return breadcrumbs.reverse()
  }

  private getBreadcrumb(state: StateDeclaration): IBreadbcrumb {
    return {
      label: state.data[ROUTE_DATA_BREADCRUMB],
      params: state.params,
      state: state.name,
    }
  }
}

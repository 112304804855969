import {
  Directive,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  HostListener,
} from '@angular/core'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

/**
 * Diretiva para usar como debounce delay para inputs
 */
@Directive({
  selector: '[debounceDelay]',
})
export class DebounceDelay implements OnInit, OnDestroy {
  @Input('debounceDelay') delay: number = 500

  @Output('debounce') debounce: EventEmitter<Object> = new EventEmitter()

  private valueChanged: Subject<string> = new Subject<string>()

  constructor() {}

  ngOnInit() {
    this.valueChanged
      .pipe(debounceTime(this.delay || 500))
      .pipe(distinctUntilChanged())
      .subscribe(model => {
        this.debounce.emit(model)
      })
  }

  ngOnDestroy() {
    this.valueChanged.unsubscribe()
  }

  @HostListener('input', ['$event'])
  onInput($event: any) {
    this.valueChanged.next($event.target.value)
  }
}

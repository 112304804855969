import { Component, Inject } from '@angular/core'
import { TranslateStaticLoader } from '../../core/translate.loader'
import { PT_BR } from './i18n/pt-br'
import { EN_US } from './i18n/en-us'

@Component({
  selector: 'app-login',
  templateUrl: './post-publico.component.html',
  styleUrls: ['./post-publico.component.scss'],
})
export class PostPublicoComponent {
  constructor(
    @Inject('post') public post: any,
    private translateLoader: TranslateStaticLoader
  ) {
    this.translateLoader.load('ptBR', PT_BR)
    this.translateLoader.load('enUS', EN_US)
  }
}

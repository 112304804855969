import { NgModule } from '@angular/core'

import { BreadcrumbComponent } from './breadcrumb.component'
import { RouteService } from '../../core/route.service'
import { CommonModule } from '@angular/common'
import { UIRouterModule } from '@uirouter/angular'

@NgModule({
  imports: [CommonModule, UIRouterModule],
  declarations: [BreadcrumbComponent],
  exports: [BreadcrumbComponent],
  providers: [RouteService],
})
export class BreadcrumbModule {}

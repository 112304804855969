import { UIRouterModule } from '@uirouter/angular'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HomeComponent } from './home.component'

const states = [
  {
    name: 'home',
    url: 'home',
    parent: 'layout',
    redirectTo: 'dashboard',
  },
  {
    name: 'dashboard',
    url: '/dashboard',
    parent: 'home',
    views: {
      'layout-content@layout': {
        component: HomeComponent,
      },
    },
    data: {
      breadcrumb: 'Dashboard',
    },
  },
]

@NgModule({
  imports: [CommonModule, UIRouterModule.forChild({ states: states })],
  declarations: [],
  exports: [UIRouterModule],
})
export class HomeRoutingModule {}

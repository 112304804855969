import { MatPaginatorIntl } from '@angular/material/paginator'
import { Injectable } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'

import { TranslateStaticLoader } from '../../core/translate.loader'
import { PT_BR } from './i18n/pt-br'
import { EN_US } from './i18n/en-us'

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
  itemsPerPageLabel: string
  nextPageLabel: string
  previousPageLabel: string

  constructor(
    private translateLoader: TranslateStaticLoader,
    private translate: TranslateService
  ) {
    super()

    this.translateLoader.load('ptBR', PT_BR)
    this.translateLoader.load('enUS', EN_US)

    this.itemsPerPageLabel = this.translate.instant('items_per_page_label')
    this.nextPageLabel = this.translate.instant('next_page_label')
    this.previousPageLabel = this.translate.instant('previous_page_label')
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `${this.translate.instant('start_page_label')} ${length}`
    }

    length = Math.max(length, 0)
    const startIndex = page * pageSize
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize

    return `${startIndex + 1} - ${endIndex} ${this.translate.instant(
      'of_page_label'
    )} ${length}`
  }
}

<ol class="breadcrumb-list">
  <li class="item">
    <a class="breadcrumb" uiSref="home" style="margin-right: -4px;">Home</a>
  </li>
  <li *ngFor="let breadcrumb of breadcrumbs; let last = last" class="item next">
    <a
      *ngIf="!last"
      class="breadcrumb"
      [uiSref]="breadcrumb.state"
      [uiParams]="breadcrumb.params"
      [uiOptions]="{ reload: true }"
      >{{ breadcrumb.label }}</a
    >
    <span *ngIf="last" class="active"> {{ breadcrumb.label }}</span>
  </li>
</ol>

export const EN_US = {
  analise_missionarios_title: 'Missionary review',
  filter_title: 'Search filters',
  cadastro: 'Record',
  tipo_instituicao: 'Sender instituition type',
  status_missionario: 'Missionary Status',
  em_analise: 'Under review',
  aprovado: 'Approved',
  rejeitado: 'Not approved',
  todos: 'All',
  bispo: 'Bishop',
  padre: 'Priest',
  diacono: 'Deacon',
  leigo: 'Layman',
  religioso: 'Religious',
  seminarista: 'Seminarian',
  outro: 'Other',
  missionarios_title: 'Missionaries',
  nome: 'Name',
  email: 'E-mail',
  data_cadastro: 'Insert Date',
  status: 'Status',
  sexo: 'Gender',
  idade: 'Age',
  missionario_title: 'Missionary details',
  informacoes_pessoais: 'Personal information',
  informacoes_missao: 'Mission information',
  qual_arquidiocese: "What's you Qual a sua archdiocese, diocese or prelature?",
  projetos: 'Projects',
  cep: 'Project Zip Code',
  rua: 'Project Address',
  descricao_projeto: 'Project Description',
  numero_rua: 'Project Street Number',
  bairro: 'Project District',
  cidade: 'Project City',
  estado: 'Project State',
  pais: 'Project Country',
  nome_projeto: 'Project Missionary Name',
  email_contato: 'Contact e-mail',
  whatsapp: 'WhatsApp',
  facebook: 'Facebook URL',
  twitter: 'Twitter URL',
  instagram: 'Instagram URL',
  informacoes_redes_sociais: 'Social media information',
  sexo_0: 'Feminine',
  sexo_1: 'Male',
  anos: 'years old',
  opcoes: 'Options',
  aprovar: 'Approve',
  rejeitar: "Don't approve",
  confirma_aprovacao: 'Confirm missionary approval?',
  sim: 'Yes',
  nao: 'No',
  cadastro_aprovado: 'Missionary approved successfully',
  cancelar: 'Cancel',
  confirmar: 'Confirm',
  motivo: 'Reason',
  cadastro_rejeitado: "Missionary don't approved",
  error_projetos: 'Error loading missionary projects',
}

import { NgModule, Optional, SkipSelf } from '@angular/core'

import { throwIfAlreadyLoaded } from './module-import-guard'
import { RouteService } from './route.service'
import { TranslateStaticLoader } from './translate.loader'
import { OAuthService } from './oauth/oauth.service'
import { DownloadService } from './download.service'

@NgModule({
  imports: [],
  providers: [
    TranslateStaticLoader,
    OAuthService,
    RouteService,
    DownloadService,
  ],
  declarations: [],
  exports: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule')
  }
}

<div class="wrapper">
  <!-- top navbar-->
  <app-header class="topnavbar-wrapper"></app-header>
  <!-- Main section-->
  <section class="content-wrapper">
    <!-- Page content-->
    <div class="page-container">
      <app-menu #menu class="menu"></app-menu>

      <div class="content" [class.menu-closed]="menu.closed">
        <ui-view name="layout-content"></ui-view>
      </div>
    </div>
  </section>
</div>

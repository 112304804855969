import { MENUS } from './menus'
import { Subject } from 'rxjs'
import { Injectable } from '@angular/core'

@Injectable()
export class MenuService {
  private toggleMenuSource = new Subject<boolean>()

  menuOpen: boolean = true

  toggleMenu$ = this.toggleMenuSource.asObservable()

  toggleMenu() {
    this.menuOpen = !this.menuOpen
    this.toggleMenuSource.next(this.menuOpen)
  }

  getMenus() {
    return MENUS
  }
}

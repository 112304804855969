import { Directive, Renderer2, ElementRef, HostListener } from '@angular/core'
import { NgModel, NG_VALUE_ACCESSOR } from '@angular/forms'
import { AbstractFormatter } from '../formatter/abstract-formatter'
import { NumberFormatterPipe } from '../pipes/numberFormatter'

/**
 * Diretiva para formatação de inputs númericos.
 */
@Directive({
  selector: '[numerico]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NumericoDirective,
      multi: true,
    },
  ],
})
export class NumericoDirective extends AbstractFormatter {
  onTouched: any
  onChange: any

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private numberFormatter: NumberFormatterPipe
  ) {
    super()
  }

  writeValue(value: any): void {
    let formatedValue = this.numberFormatter.transform(value || '')
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'value',
      formatedValue
    )
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  @HostListener('input', ['$event'])
  onInput($event: any) {
    let formatedValue = this.inputValue($event.target.value || '0')
    $event.target.value = formatedValue
    this.onChange(this.toNumber(formatedValue))
  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    this.onTouched()
  }

  /**
   * Transforma a string em float
   * @param value
   */
  private toNumber(value: string): number {
    return parseFloat(value.replace('.', '').replace(',', '.'))
  }

  /**
   * Formata o valor do input quando houver um change
   *
   * @param value
   */
  private inputValue(value: string): string {
    let result: string = value.replace('.', '')
    const period = result.indexOf(',')

    if (period > -1) {
      result = result.replace(',', '')
    } else {
      result = result + '00'
    }

    while (result.length < 3) {
      result = '0' + result
    }

    result =
      result.substring(0, result.length - 2) +
      '.' +
      result.substring(result.length - 2)

    return this.numberFormatter.transform(result)
  }
}

import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSort, MatSortable, Sort } from '@angular/material/sort'
import { AnaliseMissionariosService } from './analise-missionarios.service'
import { TableDataSource } from '../../shared/table/table-data-source'
import { TableDataCollection } from '../../shared/table/table-data-collection'
import { TranslateStaticLoader } from '../../core/translate.loader'
import { PT_BR } from './i18n/pt-br'
import { EN_US } from './i18n/en-us'

import { SearchParam } from '../../shared/factory/search-param'
import { ErrorResponse } from '../../shared/error-handler/error-response'

import { StatusCadastro, TipoMissionario } from './missionario.model'
import { FormControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { StateService } from '@uirouter/core'

@Component({
  selector: 'app-analise-missionarios',
  templateUrl: './analise-missionarios.component.html',
  styleUrls: ['./analise-missionarios.component.scss'],
})
export class AnaliseMissionariosComponent implements OnInit, OnDestroy {
  selected: any

  tipoInstituicaoTodos: any = {
    dsTipoInstituicao: '',
  }
  statusTodos = 'TODOS'

  tipoCadastroList = [
    StatusCadastro.EM_ANALISE,
    StatusCadastro.APROVADO,
    StatusCadastro.REJEITADO,
  ]
  tipoInstituicaoList = [this.tipoInstituicaoTodos]
  tipoStatusList = [
    this.statusTodos,
    TipoMissionario.BISPO,
    TipoMissionario.PADRE,
    TipoMissionario.DIACONO,
    TipoMissionario.LEIGO,
    TipoMissionario.RELIGIOSO,
    TipoMissionario.SEMINARISTA,
    TipoMissionario.OUTRO,
  ]

  displayedColumns = [
    'regUsuario.dsNome',
    'regUsuario.dsEmail',
    'regUsuario.dhInsert',
    'tpStatus',
    'regTipoInstituicao.dsTipoInstituicao',
  ]

  tableDataSource: TableDataSource | null
  tableDataCollection: TableDataCollection | null

  paginatorData: any = {
    length: 0,
    pageSize: 25,
    pageIndex: 0,
  }

  cadastroControl = new FormControl(StatusCadastro.EM_ANALISE)
  instituicaoControl = new FormControl(this.tipoInstituicaoTodos)
  statusControl = new FormControl(this.statusTodos)

  cadastroOnChange: Subscription
  stautsOnChange: Subscription
  instituicaoOnChange: Subscription

  @ViewChild(MatPaginator) paginator: MatPaginator

  @ViewChild(MatSort, { static: true }) sort: MatSort

  constructor(
    private service: AnaliseMissionariosService,
    private translateLoader: TranslateStaticLoader,
    private errorResponse: ErrorResponse,
    private translate: TranslateService,
    private stateService: StateService
  ) {
    this.tableDataCollection = new TableDataCollection()

    this.translateLoader.load('ptBR', PT_BR)
    this.translateLoader.load('enUS', EN_US)

    this.tipoInstituicaoTodos.dsTipoInstituicao = this.translate.instant(
      'todos'
    )
  }

  ngOnInit() {
    let sort: MatSortable = {
      id: 'regUsuario.dhInsert',
      start: 'desc',
      disableClear: true,
    }

    this.sort.sort(sort)

    this.cadastroOnChange = this.cadastroControl.valueChanges.subscribe(() =>
      this.resetPageAndLoadData()
    )
    this.stautsOnChange = this.statusControl.valueChanges.subscribe(() =>
      this.resetPageAndLoadData()
    )
    this.instituicaoOnChange = this.instituicaoControl.valueChanges.subscribe(
      () => this.resetPageAndLoadData()
    )

    this.service.findAllInstituicao().subscribe(
      data =>
        (this.tipoInstituicaoList = this.tipoInstituicaoList.concat(
          (<any>data).data
        )),
      error => this.errorResponse.showMessage(error)
    )
  }

  ngOnDestroy() {
    this.cadastroOnChange.unsubscribe()
    this.stautsOnChange.unsubscribe()
    this.instituicaoOnChange.unsubscribe()
  }

  loadData(sort: Sort = null) {
    this.onSelect(null)

    const search: SearchParam = this.buildFilters()

    if (sort && sort.active && sort.direction !== '') {
      search
        .put('orderBy', ':', sort.active)
        .put('orderType', ':', sort.direction.toUpperCase())
    } else {
      search.remove('orderBy').remove('orderType')
    }

    let searcString = search.build() + this.buildPageParams()

    this.service.findAll(searcString).subscribe(
      data => this.onLoadDataSuccess(data),
      error => this.errorResponse.showMessage(error)
    )
  }

  onLoadDataSuccess(data: any): void {
    this.tableDataCollection.data = data.data.data.map(item => {
      switch (item.tpStatus) {
        case 0:
          item.tpStatus = TipoMissionario.BISPO
          break
        case 1:
          item.tpStatus = TipoMissionario.PADRE
          break
        case 2:
          item.tpStatus = TipoMissionario.DIACONO
          break
        case 3:
          item.tpStatus = TipoMissionario.LEIGO
          break
        case 4:
          item.tpStatus = TipoMissionario.RELIGIOSO
          break
        case 5:
          item.tpStatus = TipoMissionario.SEMINARISTA
          break
        case 6:
          item.tpStatus = TipoMissionario.OUTRO
          break
      }

      switch (item.tpStatusCadastro) {
        case 0:
          item.tpStatusCadastro = StatusCadastro.EM_ANALISE
          break
        case 1:
          item.tpStatusCadastro = StatusCadastro.APROVADO
          break
        case 2:
          item.tpStatusCadastro = StatusCadastro.REJEITADO
          break
      }

      return item
    })

    this.tableDataSource = new TableDataSource(this.tableDataCollection)
    this.paginatorData = {
      ...this.paginatorData,
      length: data.data.total,
    }
  }

  onSelect(row: Object): void {
    this.selected = this.selected !== row ? row : null
  }

  openDetails() {
    if (!this.selected) {
      return
    }

    this.stateService.go('analise_detalhes', {
      id: this.selected.id,
    })
  }

  onPageChange(event: PageEvent) {
    this.paginatorData.pageIndex = event.pageIndex
    this.paginatorData.pageSize = event.pageSize

    this.loadData()
  }

  private buildFilters(): SearchParam {
    const params: SearchParam = new SearchParam()

    if (this.cadastroControl.value) {
      params.put('tpStatusCadastro', ':', this.cadastroControl.value)
    }

    if (
      this.statusControl.value &&
      this.statusControl.value != this.statusTodos
    ) {
      params.put('tpStatus', ':', this.statusControl.value)
    }

    if (
      this.instituicaoControl.value &&
      this.instituicaoControl.value != this.tipoInstituicaoTodos &&
      this.instituicaoControl.value.id
    ) {
      params.put('regTipoInstituicao.id', ':', this.instituicaoControl.value.id)
    }

    return params
  }

  private buildPageParams(): string {
    return `&size=${this.paginatorData.pageSize}&page=${this.paginatorData.pageIndex}`
  }

  private resetPageAndLoadData() {
    this.paginatorData.length = 0
    this.paginatorData.pageIndex = 0

    this.loadData(this.sort)
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core'
import { StateService } from '@uirouter/angular'
import { OAuthService } from 'src/app/core/oauth/oauth.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'user',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss'],
})
export class UsuarioComponent implements OnInit, OnDestroy {
  showPopover: boolean = false
  currentUser: any

  private subscription: Subscription

  constructor(
    private stateService: StateService,
    private oauth: OAuthService
  ) {}

  ngOnInit() {
    this.loadUser()
    this.subscription = this.oauth.currentUser$.subscribe(() => this.loadUser())
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  private loadUser() {
    this.currentUser = {
      dsNomeUsuario: this.oauth.currentUser().dsNome,
    }
  }

  togglePopover() {
    this.showPopover = !this.showPopover
  }

  closePopover() {
    this.showPopover = false
  }

  logout() {
    this.stateService.go('login')
  }
}

import { Component, Input, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

/**
 * Componente de loading para exibir progresso indeterminado
 */
@Component({
  selector: 'loading-modal',
  templateUrl: './loading.modal.component.html',
  styleUrls: ['./loading.modal.component.scss'],
})
export class LoadingModalComponent {
  @Input()
  message: string

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message || 'Carregando, aguarde...'
  }
}

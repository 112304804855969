import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslateStaticLoader {
    
    constructor(private translate: TranslateService) { }

	public load(lang: string, strings: any) {
        this.translate.setTranslation(lang, strings, true);
    }
    
}
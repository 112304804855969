<div class="wrapper">
  <section class="parallax-background login-page">
    <div id="login-v2-form-wrapper" class="flex-scrollable mat-whiteframe-8dp">
      <div id="login-v2-form">
        <div class="logo">
          <img src="assets/logo-ad-gentes.png" alt="Logo" />
        </div>

        <form class="form-login" (ngSubmit)="login()" #f="ngForm" novalidate>
          <mat-form-field class="mat-block">
            <mat-icon
              matPrefix
              class="fa fa-user font-grey-salt icon-pesquisa"
            ></mat-icon>
            <input
              matInput
              type="email"
              name="email"
              placeholder="{{ 'email' | translate }}"
              required
              [formControl]="emailFormControl"
              [(ngModel)]="model.email"
            />
            <mat-error *ngIf="emailFormControl.hasError('email')">
              {{ 'invalid_email' | translate }}
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              {{ 'required_email' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mat-block">
            <mat-icon
              matPrefix
              class="fa fa-key font-grey-salt icon-pesquisa"
            ></mat-icon>
            <input
              matInput
              type="password"
              name="password"
              placeholder="{{ 'password' | translate }}"
              required
              [formControl]="passwordFormControl"
              [(ngModel)]="model.password"
            />
            <mat-error *ngIf="passwordFormControl.hasError('required')">
              {{ 'required_password' | translate }}
            </mat-error>
          </mat-form-field>

          <button [disabled]="loading" mat-button class="login-button">
            {{ 'login_button' | translate }}
          </button>
        </form>
      </div>
    </div>
  </section>
</div>

<section>
  <div class="section-title">
    <h1>{{ 'missionario_title' | translate }}</h1>
  </div>

  <div class="form-container">
    <form #f="ngForm" [formGroup]="form" novalidate>
      <mat-card>
        <mat-card-title class="has-border-bottom">
          {{ 'informacoes_pessoais' | translate }}
        </mat-card-title>

        <mat-card-content>
          <div class="info-container">
            <div class="perfil-container">
              <div
                class="imagem-perfil"
                [style.background-image]="'url(' + fotoPerfil + ')'"
              ></div>
            </div>
            <div class="info-field-container grid-container">
              <div class="grid-flex">
                <div class="col-s-6">
                  <mat-form-field class="block">
                    <input
                      matInput
                      name="dsNome"
                      placeholder="{{ 'nome' | translate }}"
                      [formControl]="nomeFormControl"
                    />
                  </mat-form-field>
                </div>
                <div class="col-s-6">
                  <mat-form-field class="block">
                    <input
                      matInput
                      name="dsEmail"
                      placeholder="{{ 'email' | translate }}"
                      [formControl]="emailFormControl"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="grid-flex">
                <div class="col-s-6">
                  <mat-form-field class="block">
                    <input
                      matInput
                      name="nrIdade"
                      placeholder="{{ 'idade' | translate }}"
                      [formControl]="idadeFormControl"
                    />
                  </mat-form-field>
                </div>
                <div class="col-s-6">
                  <mat-form-field class="block">
                    <input
                      matInput
                      name="tpStatus"
                      placeholder="{{ 'status' | translate }}"
                      [formControl]="statusCadastroFormControl"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="grid-flex">
                <div class="col-s-6">
                  <mat-form-field class="block">
                    <input
                      matInput
                      name="dtCadastro"
                      placeholder="{{ 'data_cadastro' | translate }}"
                      [formControl]="dtCadastroFormControl"
                    />
                  </mat-form-field>
                </div>
                <div class="col-s-6">
                  <mat-form-field class="block">
                    <input
                      matInput
                      name="tpSexo"
                      placeholder="{{ 'sexo' | translate }}"
                      [formControl]="sexoFormControl"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-title class="has-border-bottom">
          {{ 'informacoes_missao' | translate }}
        </mat-card-title>

        <mat-card-content>
          <div class="grid-container">
            <div class="grid-flex">
              <div class="col-s-6">
                <mat-form-field class="block">
                  <input
                    matInput
                    name="dsRegInstituicao"
                    placeholder="{{ 'tipo_instituicao' | translate }}"
                    [formControl]="instituicaoFormControl"
                  />
                </mat-form-field>
              </div>
              <div class="col-s-6">
                <mat-form-field class="block">
                  <input
                    matInput
                    name="dsDiocese"
                    placeholder="{{ 'qual_arquidiocese' | translate }}"
                    [formControl]="arquidioceseFormControl"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="subtitle has-border-bottom">
            {{ 'projetos' | translate }}
          </div>

          <div
            class="grid-container"
            *ngFor="let projeto of projetos; let last = last; let first = first"
            [ngClass]="{
              'separador-projeto': !first,
              'has-border-bottom': !last
            }"
          >
            <div class="grid-flex spaced">
              <div class="col-s-6 space-right">
                <div class="grid-flex">
                  <mat-form-field class="block">
                    <input
                      matInput
                      disabled
                      name="dsNomeProjeto"
                      placeholder="{{ 'nome_projeto' | translate }}"
                      [value]="projeto.dsNome"
                    />
                  </mat-form-field>
                </div>
                <div class="grid-flex">
                  <mat-form-field class="block">
                    <input
                      matInput
                      disabled
                      name="dsPais"
                      placeholder="{{ 'pais' | translate }}"
                      [value]="projeto.regPais.dsNome"
                    />
                  </mat-form-field>
                </div>
                <div class="grid-flex">
                  <mat-form-field class="block">
                    <input
                      matInput
                      disabled
                      name="dsEstado"
                      placeholder="{{ 'estado' | translate }}"
                      [value]="projeto.dsEstado"
                    />
                  </mat-form-field>
                </div>
                <div class="grid-flex">
                  <mat-form-field class="block">
                    <input
                      matInput
                      disabled
                      name="dsCidade"
                      placeholder="{{ 'cidade' | translate }}"
                      [value]="projeto.dsCidade"
                    />
                  </mat-form-field>
                </div>
                <div class="grid-flex">
                  <mat-form-field class="block">
                    <input
                      matInput
                      disabled
                      name="dsBairro"
                      placeholder="{{ 'bairro' | translate }}"
                      [value]="projeto.dsBairro"
                    />
                  </mat-form-field>
                </div>
                <div class="grid-flex">
                  <mat-form-field class="block">
                    <input
                      matInput
                      disabled
                      name="nrRua"
                      placeholder="{{ 'numero_rua' | translate }}"
                      [value]="projeto.nrRua"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="col-s-6 space-left">
                <div class="grid-flex">
                  <mat-form-field class="block">
                    <textarea
                      class="descricao-projeto"
                      matInput
                      disabled
                      name="dsProjeto"
                      placeholder="{{ 'descricao_projeto' | translate }}"
                      >{{ projeto.dsDescricao }}</textarea
                    >
                  </mat-form-field>
                </div>
                <div class="grid-flex">
                  <mat-form-field class="block">
                    <input
                      matInput
                      disabled
                      name="dsRua"
                      placeholder="{{ 'rua' | translate }}"
                      [value]="projeto.dsRua"
                    />
                  </mat-form-field>
                </div>
                <div class="grid-flex">
                  <mat-form-field class="block">
                    <input
                      matInput
                      disabled
                      name="nrCep"
                      placeholder="{{ 'cep' | translate }}"
                      [value]="projeto.nrCep || ' '"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-title class="has-border-bottom">
          {{ 'informacoes_redes_sociais' | translate }}
        </mat-card-title>

        <mat-card-content>
          <div class="grid-container">
            <div class="grid-flex">
              <div class="col-s-6">
                <mat-form-field class="block">
                  <input
                    matInput
                    name="dsUrlInstagram"
                    placeholder="{{ 'instagram' | translate }}"
                    [formControl]="instagramFormControl"
                  />
                </mat-form-field>
              </div>
              <div class="col-s-6">
                <mat-form-field class="block">
                  <input
                    matInput
                    name="dsUrlTwitter"
                    placeholder="{{ 'twitter' | translate }}"
                    [formControl]="twitterFormControl"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="grid-flex">
              <div class="col-s-6">
                <mat-form-field class="block">
                  <input
                    matInput
                    name="dsUrlFacebook"
                    placeholder="{{ 'facebook' | translate }}"
                    [formControl]="facebookFormControl"
                  />
                </mat-form-field>
              </div>
              <div class="col-s-6">
                <mat-form-field class="block">
                  <input
                    matInput
                    name="dsNrWhatsapp"
                    placeholder="{{ 'whatsapp' | translate }}"
                    [formControl]="whatsappFormControl"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="grid-flex">
              <div class="col-s-6">
                <mat-form-field class="block">
                  <input
                    matInput
                    name="dsEmailContato"
                    placeholder="{{ 'email_contato' | translate }}"
                    [formControl]="emailContatoFormControl"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <div class="button-container fixed-bottom">
        <astutus-fab
          class="fab"
          [options]="fabOptions"
          [disabled]="saving"
          *ngIf="missionario?.tpStatusCadastro === 0"
        ></astutus-fab>
      </div>
    </form>
  </div>
</section>

import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AstutusComboboxItem } from './item/astutus-combobox-item.component'
import { AstutusComboboxOverlay } from './overlay/astutus-combobox-overlay.component'

import { AstutusCombobox } from './astutus-combobox.component'
import { MatInputModule } from '@angular/material/input'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  declarations: [AstutusCombobox, AstutusComboboxOverlay, AstutusComboboxItem],
  exports: [AstutusCombobox, AstutusComboboxOverlay, AstutusComboboxItem],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AstutusComboboxModule {}

import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core'
import { TranslateStaticLoader } from '../../core/translate.loader'
import { TranslateService } from '@ngx-translate/core'
import { PT_BR } from './i18n/pt-br'
import { EN_US } from './i18n/en-us'
import { Periodo } from './periodo'
import { MatSnackBar } from '@angular/material/snack-bar'

/**
 * Componente de filtro com períodos pré-definidos e uma opção de datas personalizadas
 */
@Component({
  selector: 'filtro-periodo',
  templateUrl: './filtro-periodo.component.html',
  styleUrls: ['./filtro-periodo.component.scss'],
})
export class FiltroPeriodo implements OnInit {
  @Input()
  mostrarHoras: boolean = false

  @Input()
  incluirOpcaoNenhum: boolean = false

  @Input()
  label: string

  /**
   * Lista com os períodos pré-definidos
   */
  public periodos: Periodo[] = []

  /**
   * Período atual selecionado
   */
  public periodo: Periodo = new Periodo()

  /**
   * Indica se a data é personalizada ou pré-definida
   */
  public customDate: boolean = false

  /**
   * Temporários para armazenar a hora do filtro
   */
  public horaInicial: string
  public horaFinal: string

  /**
   * Dispara o evento de change ao alterar o período ou a data personalizada
   */
  @Output()
  public periodoChanged: EventEmitter<Periodo> = new EventEmitter<Periodo>()

  constructor(
    private translateLoader: TranslateStaticLoader,
    private translate: TranslateService,
    private snackBar: MatSnackBar
  ) {
    this.translateLoader.load('ptBR', PT_BR)
    this.translateLoader.load('enUS', EN_US)
  }

  /**
   * Inicializa os períodos pré-definidos e o período inicial selecionado,
   * disparando o evento change de notificação
   */
  public ngOnInit(): void {
    if (this.incluirOpcaoNenhum) {
      this.periodos.push(this.getNenhum())
    }

    this.periodos.push(this.getToday())
    this.periodos.push(this.getThisWeek())
    this.periodos.push(this.getLast7Days())
    this.periodos.push(this.getThisMonth())
    this.periodos.push(this.getLast30Days())
    this.periodos.push(this.getLastMonth())
    this.periodos.push(this.getCustom())

    this.periodo = this.periodos[0]

    this.filtersChanged()
  }

  private getNenhum(): Periodo {
    return {
      descricao: this.translate.instant('nenhum'),
      dataInicial: null,
      dataFinal: null,
    }
  }

  /**
   * Monta o período Hoje
   */
  private getToday(): Periodo {
    return {
      descricao: this.translate.instant('hoje'),
      dataInicial: new Date(),
      dataFinal: new Date(),
    }
  }

  /**
   * Monta o período Esta semana
   */
  private getThisWeek(): Periodo {
    return {
      descricao: this.translate.instant('esta_semana'),
      dataInicial: this.getWeekStart(),
      dataFinal: this.getWeekEnd(),
    }
  }

  /**
   * Monta o período Últimos 7 dias
   */
  private getLast7Days(): Periodo {
    return {
      descricao: this.translate.instant('ultimos_7_dias'),
      dataInicial: this.getTodayMinus(6),
      dataFinal: new Date(),
    }
  }

  /**
   * Monta o período Este mês
   */
  private getThisMonth(): Periodo {
    return {
      descricao: this.translate.instant('este_mes'),
      dataInicial: this.getMonthStart(),
      dataFinal: this.getMonthEnd(),
    }
  }

  /**
   * Monta o período Últimos 30 dias
   */
  private getLast30Days(): Periodo {
    return {
      descricao: this.translate.instant('ultimos_30_dias'),
      dataInicial: this.getTodayMinus(29),
      dataFinal: new Date(),
    }
  }

  /**
   * Monta o período Mês passado
   */
  private getLastMonth(): Periodo {
    return {
      descricao: this.translate.instant('mes_passado'),
      dataInicial: this.getLastMonthStart(),
      dataFinal: this.getLastMonthEnd(),
    }
  }

  /**
   * Monta o período Personalizado
   */
  private getCustom(): Periodo {
    return {
      descricao: this.translate.instant('personalizado'),
      dataInicial: this.getTodayMinus(6),
      dataFinal: new Date(),
    }
  }

  /**
   * Monta a data no domingo da semana atual
   */
  private getWeekStart(): Date {
    const date: Date = new Date()
    date.setDate(date.getDate() - date.getDay())

    return date
  }

  /**
   * Monta a data no sábado da semana atual
   */
  private getWeekEnd(): Date {
    const date: Date = new Date()
    date.setDate(date.getDate() + 6 - date.getDay())

    return date
  }

  /**
   * Monta a data e subtrai o número de dias
   * @param days
   */
  private getTodayMinus(days: number): Date {
    const date: Date = new Date()
    date.setDate(date.getDate() - days)

    return date
  }

  /**
   * Monta a data no primeiro dia do mês
   */
  private getMonthStart(): Date {
    const date: Date = new Date()
    date.setDate(1)

    return date
  }

  /**
   * Monta a data no último dia do mês
   */
  private getMonthEnd(): Date {
    const date: Date = new Date()
    date.setMonth(date.getMonth() + 1)
    date.setDate(0)

    return date
  }

  /**
   * Monta a data do primeiro dia do mês passado
   */
  private getLastMonthStart(): Date {
    const date: Date = this.getMonthStart()
    date.setMonth(date.getMonth() - 1)

    return date
  }

  /**
   * Monta a data do último dia do mês passado
   */
  private getLastMonthEnd(): Date {
    const date: Date = this.getLastMonthStart()
    date.setMonth(date.getMonth() + 1)
    date.setDate(0)

    return date
  }

  /**
   * Notifica quando alterar uma data do período
   */
  public filtersChanged() {
    this.periodoChanged.emit(this.periodo)
  }

  /**
   * Notifica quando altera o período todo
   */
  public changePeriodo() {
    this.customDate = this.periodos[this.periodos.length - 1] == this.periodo
    this.filtersChanged()
  }

  /**
   * Notifica quando há mudança na data inicial através do datePicker
   * @param event
   */
  public dataInicialChanged(event) {
    if (this.periodo.dataInicial != event.value && event.value) {
      this.periodo.dataInicial = event.value
      this.filtersChanged()
    }
  }

  /**
   * Notifica quando há mudança na data final através do datePicker
   * @param event
   */
  public dataFinalChanged(event) {
    if (this.periodo.dataFinal != event.value && event.value) {
      this.periodo.dataFinal = event.value
      this.filtersChanged()
    }
  }

  /**
   * Valida a hora final e notifica da mudança
   */
  public horaFinalChanged($event) {
    if (
      this.validarHora(this.horaFinal, this.translate.instant('hora_final')) &&
      this.formatarHora(this.horaFinal) !== this.periodo.horaFinal
    ) {
      this.periodo.horaFinal = this.formatarHora(this.horaFinal)
      this.filtersChanged()
    }
  }

  /**
   * Se a hora não for vazia adiciona ":" no meio
   */
  private formatarHora(hora: string): string {
    if (hora) {
      return hora.substring(0, 2) + ':' + hora.substring(2)
    }
    return ''
  }

  /**
   * Valida a hora inicial e notifica da mudança
   * @param
   */
  public horaInicialChanged($event) {
    if (
      this.validarHora(
        this.horaInicial,
        this.translate.instant('hora_inicial')
      ) &&
      this.formatarHora(this.horaInicial) !== this.periodo.horaInicial
    ) {
      this.periodo.horaInicial = this.formatarHora(this.horaInicial)
      this.filtersChanged()
    }
  }

  /**
   * Valida se o horário informado é valido
   *
   * @param hora
   */
  private validarHora(hora: string, campo: string) {
    if (!hora) {
      return true
    }

    if (hora.length < 4) {
      return false
    }

    const h: number = parseInt(hora.substring(0, 2))
    const m: number = parseInt(hora.substring(2))

    if (h > 23 || m > 59) {
      return this.showMessage(campo + ' ' + this.translate.instant('invalida'))
    }

    return true
  }

  private showMessage(msg) {
    this.snackBar.open(msg, null, {
      duration: 5000,
    })
  }
}

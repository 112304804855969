<mat-form-field #inputContainer (click)="onInputFocus()">
  <input
    #input
    [tabindex]="tabindex"
    matInput
    [placeholder]="label"
    [(ngModel)]="inputValue"
    [required]="required"
    [name]="name"
    [formControl]="formControlRef"
    (focus)="onInputFocus()"
    (keydown)="onInputKeyDown($event)"
  />
  <mat-error>{{ requiredMessage }}</mat-error>

  <button
    type="button"
    mat-icon-button
    matSuffix
    tabindex="-1"
    [disabled]="isDisabled"
  >
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
</mat-form-field>

<astutus-combobox-overlay
  #overlay
  tabindex="-1"
  [items]="filteredItems"
  [position-target]="getPositionTarget()"
  [selected]="selectedItem"
  (selected-change)="onSelectChange($event)"
  style="max-height: 305px !important;"
  [selected-label]="selectedLabel"
>
</astutus-combobox-overlay>

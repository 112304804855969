import { BehaviorSubject } from 'rxjs'

/**
 * @whatItDoes Fornecesse uma coleção de dados.
 *
 * @description Esta classe será utilizada fornecer uma coleção de dados
 * que serão utilizados no data source da MatTable.
 *
 */
export class TableDataCollection {
  /** Stream that emits whenever the data has been modified. */
  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])

  constructor(data?: any[]) {
    if (data) this.dataChange.next(data)
  }

  /**
   * Retorna os dados armazenados na coleção
   *
   * @returns {any[]}
   */
  get data(): any[] {
    return this.dataChange.value
  }

  /**
   * Atribui novos dados à coleção
   *
   * @param {any[]} data
   */
  set data(data: any[]) {
    this.dataChange.next(data)
  }
}

<div class="fab-parent" (mouseleave)="close()">
  <button
    [disabled]="disabled"
    #anchor
    mat-fab
    class="button"
    [color]="options.color"
    [matTooltip]="options.tooltip"
    [matTooltipPosition]="options.tooltipPosition || 'left'"
    (mouseenter)="open()"
  >
    <mat-icon *ngIf="options.class" [class]="options.class"></mat-icon>
    <mat-icon *ngIf="options.icon">{{ options.icon }}</mat-icon>
  </button>
  <div #background class="background"></div>
  <div #content class="fab-content" [class.open]="isOpen">
    <button
      mat-mini-fab
      [color]="item.color"
      *ngFor="let item of options.items"
      [matTooltip]="item.tooltip"
      [matTooltipPosition]="item.tooltipPosition || 'left'"
      (click)="close(); item.onClick()"
    >
      <mat-icon *ngIf="item.class" [class]="item.class"></mat-icon>
      <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
    </button>
  </div>
</div>

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { DataFactory } from '../../shared/factory/data-factory'
import { environment } from 'src/environments/environment'

@Injectable()
export class PostPublicoService {
  private apiUrl

  constructor(private http: HttpClient) {
    this.apiUrl = `${environment.apiUrl}/post/public`
  }

  getPublicPost(uuid: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${uuid}`)
  }
}

import { Component } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'

import { UIRouter, StateService } from '@uirouter/angular'
import { MatSnackBar } from '@angular/material/snack-bar'

import { OAuthService } from '../../core/oauth/oauth.service'
import { PT_BR } from './i18n/pt-br'
import { EN_US } from './i18n/en-us'
import { TranslateStaticLoader } from '../../core/translate.loader'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-resetar-senha',
  templateUrl: './resetar-senha.component.html',
  styleUrls: ['./resetar-senha.component.scss'],
})
export class ResetarSenhaComponent {
  model: any = {}
  loading = false

  passwordFormControl = new FormControl('', [Validators.required])

  repeateFormControl = new FormControl('', [Validators.required])

  constructor(
    private oauth: OAuthService,
    private uiRouter: UIRouter,
    private stateService: StateService,
    private snackBar: MatSnackBar,
    private translateLoader: TranslateStaticLoader,
    private translateService: TranslateService
  ) {
    this.translateLoader.load('ptBR', PT_BR)
    this.translateLoader.load('enUS', EN_US)
  }

  resetPassword() {
    if (this.passwordFormControl.valid) {
      // this.oauth
      //   .resetPassword(this.uiRouter.globals.params.token, this.model.password)
      //   .subscribe(
      //     () => this.onChangePasswordSuccess(),
      //     error => this.onChangePasswordError(error)
      //   )
    }
  }

  onChangePasswordSuccess() {
    this.snackBar.open(this.translateService.instant('success'), 'Ok', {
      duration: 5000,
    })
    this.stateService.go('login')
  }

  onChangePasswordError(error) {
    this.snackBar.open(this.translateService.instant('error'), 'Ok', {
      duration: 5000,
    })
    this.loading = false
  }
}

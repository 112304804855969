import { SharedModule } from '../../shared/shared.module';
import { NgModule } from '@angular/core';
import { UsuarioComponent } from './usuario.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    UsuarioComponent
  ],
  exports: [
    UsuarioComponent
  ]
})
export class UsuarioModule { }

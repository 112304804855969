export const PT_BR = {
    periodo: 'Período',
    data_inicial: 'Data inicial',
    data_final: 'Data final',
    hoje: 'Hoje',
    esta_semana: 'Esta semana (dom. a sáb)',
    ultimos_7_dias: 'Últimos 7 dias',
    este_mes: 'Este mês',
    ultimos_30_dias: 'Últimos 30 dias',
    mes_passado: 'Mês passado',
    personalizado: 'Personalizado',
    hora_inicial: 'Hora inicial',
    hora_final: 'Hora final',
    invalida: 'inválida',
    nenhum: 'Nenhum'
}

<div
  class="user-container"
  [class.popover-open]="showPopover"
  click-outside
  (clickOutside)="closePopover()"
  (click)="togglePopover()"
>
  <span class="nome font-white">{{ currentUser.dsNomeUsuario }}</span>
  <mat-icon
    matPrefix
    class="fa fa-user fa-user-menu font-white icon-pesquisa"
  ></mat-icon>
  <div class="popover" *ngIf="showPopover">
    <mat-card>
      <div class="itens">
        <a class="item" uiSref="conta">
          <mat-icon class="fa fa-user"></mat-icon>
          <span class="label">Minha Conta</span>
        </a>
        <a class="item hidden">
          <mat-icon class="fa fa-cog"></mat-icon>
          <span class="label">Configurações</span>
        </a>
        <a class="item" (click)="logout()">
          <mat-icon class="fa fa-sign-out"></mat-icon>
          <span class="label">Sair</span>
        </a>
      </div>
    </mat-card>
  </div>
</div>

import { NgModule } from '@angular/core'
import { UIRouterModule } from '@uirouter/angular'

import { SharedModule } from '../../shared/shared.module'
import { PostPublicoComponent } from './post-publico.component'
import { PostPublicoRoutingModule } from './post-publico.routing.module'
import { PostPublicoService } from './post-publico.service'

@NgModule({
  imports: [SharedModule, UIRouterModule, PostPublicoRoutingModule],
  declarations: [PostPublicoComponent],
  providers: [PostPublicoService],
})
export class PostPublicoModule {}
